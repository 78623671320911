<template>
  <div class="gq-detail">
    <div
            class="goback"
            @click="goback"
    >
      <el-button
              type="primary"
              style="width: 100px;"
              @click="goback"
      >返回</el-button
      >
    </div>
    <div class="content-module">
      <div class="mr21">
        <el-carousel
                trigger="click"
                height="360px"
                v-if="$route.query.type == 0 && info.picsurl"
        >
          <el-carousel-item v-for="(item, index) in info.picsurl" :key="index">
            <img class="banner-img" :src="'/info-admin' + item" />
          </el-carousel-item>
        </el-carousel>
        <el-carousel
                trigger="click"
                height="360px"
                v-if="$route.query.type == 1 && info.pics"
        >
          <el-carousel-item v-for="(item, index) in info.pics" :key="index">
            <img class="banner-img" :src="'/info-admin' + item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="cont-mid">
        <h3>{{ $route.query.type == 1 ? this.$route.query.purchaseType == 1?'个人求购': '企业求购' : '供应' }}：{{ info.name }}</h3>
        <div class="notice">{{ info.descriptions }}</div>
        <ul>
          <li style="width: 100%" v-if="$route.query.purchaseType == 2">
            <span>公司名称：</span><span>{{ info.company }}</span>
          </li>
          <li style="width: 100%" v-if="$route.query.purchaseType == 2">
            <span>公司地址：</span><span>{{ info.companyAddress }}</span>
          </li>
          <li>规格：{{ info.spec }}</li>
          <li v-if="$route.query.type == 0">数量：{{ info.stockcount }}</li>
          <li v-else>数量：{{ info.buycount }}</li>
          <li>产地：{{ info.addr }}</li>
          <li style="color: red">价格：{{ info.price }}</li>
          <li v-if="$route.query.type == 0">
            货源所在地：{{ info.stockaddr }}
          </li>
          <li v-else>货源所在地：{{ info.deliveryaddr }}</li>
          <li style="color: red">有效期：剩余{{ info.deadline }}天</li>
          <li v-if="$route.query.purchaseType == 2 && info.free == 1">是否付费：免费</li>
          <li v-if="$route.query.purchaseType == 2 && info.free == 2">是否付费：付费</li>
          <li v-if="$route.query.purchaseType == 2">费用：{{ info.fee }}</li>
          <li style="width: 100%">
            <span>发布时间：</span><span>{{ info.publishdatetime }}</span>
          </li>
          <!-- <li style="width: 100%">质量标准：{{ info.insp_state }}</li> -->
        </ul>
        <div class="mid-bottom mt20">
          <!-- <el-button class="btn f16 btn-orange" @click="getComment"
            >询价留言</el-button
          >
          <el-button class="btn f16 btn-info" @click="toMyShop"
            >进入店铺</el-button
          > -->
          <div class="left-btn" @click="getComment">询价留言</div>
          <div class="right-btn" @click="toMyShop">进入店铺</div>
        </div>
      </div>
      <div class="cont-right">
        <h3>
          <img class="home-img" src="@/assets/imgs/myhome.png" alt="" />店主信息
        </h3>
        <ul>
          <li>联系人：{{ parlourForm.company_name }}</li>
          <li>电 话：{{ parlourForm.contact_tel }}</li>
          <!-- <li>实名认证： {{ parlourForm.is_real_name == 1 ? '是' : '' }}</li> -->
          <li style="height: 28px:line-height:28px;">
            实名认证：
            <img
                    style="height: 35px; vertical-align: middle"
                    src="@/assets/imgs/smrz.png"
                    alt=""
            />
          </li>
          <li>手机号：{{ parlourForm.personalphone }}</li>
          <li>所在地：{{ parlourForm.address }}</li>
          <li>地 址：{{ parlourForm.addressmore }}</li>
          <li>浏览量：{{ parlourForm.pageview }}次</li>
          <li>创店时间：{{ parlourForm.createDate }}</li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <h3>商品描述</h3>
      <div class="body-contnet">{{ info.descriptions }}</div>
      <h3>质检报告</h3>
      <div>
        <el-image
                v-if="info.zj"
                :src="'/info-admin' + info.zj"
                style="width: 100%; height: 100%"
        ></el-image>
      </div>
      <h3>好货推荐</h3>
      <div v-if="$route.query.type == 0" class="content-module-tuijian">
        <div
                v-for="(item, index) in productList"
                :key="index"
                class="content-div"
        >
          <div class="img" @click="toDetail(item, 0)">
            <div v-if="item.mtop == 2" class="type showTj"></div>
            <div v-if="item.mtop == 1" class="type showJs"></div>
            <div v-if="item.zjState == 1" class="type showZj"></div>
            <el-image
                    v-if="item.picsurl"
                    :src="'/info-admin' + item.picsurl[0]"
                    style="width: 100%; height: 100%"
            ></el-image>
          </div>
          <div class="cont-info">
            <el-row class="mb10">
              <el-col :span="8">
                品名：<span class="proName" @click="toSeekDetail(item, 0)">{{ item.name }}</span>
              </el-col>
              <el-col :span="8">规格：{{ item.spec }} </el-col>
              <el-col :span="8">单价：{{ item.price }} </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8"> 数量：{{ item.stockcount }}{{item.unit}} </el-col>
              <el-col :span="8">产地：{{ item.addr }} </el-col>
              <el-col :span="8">货源所在地：{{ item.stockaddr }} </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8">
                发布：{{ item.publishdatetime ? item.publishdatetime.split(' ')[0] : '' }}</el-col
              >
              <el-col :span="8"
              >有效期：剩余{{ item.deadline }}天
              </el-col>
              <el-col :span="8" class="colorRed">
                <div class="smrz">
                  <div>实名认证：</div>
                  <img
                          style="height: 35px; text-align: center"
                          src="@/assets/imgs/smrz.png"
                          alt=""
                  />
                </div>
              </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8" class="colorRed">
                联系人：{{ item.contacter }}</el-col
              >
              <el-col :span="16" class="colorRed"
              >手机：{{ item.contacttel }}
              </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="24" class="colorRed">
                地址：{{ item.idcadeno }}</el-col
              >
            </el-row>
          </div>
        </div>
      </div>
      <div v-else-if="$route.query.type == 1" class="content-module-tuijian">
        <div
                v-for="(item, index) in productList"
                :key="index"
                class="content-div"
        >
          <div class="img" @click="toSeekDetail(item, 1)">
            <div v-if="item.type == 1" class="typeOther showGr"></div>
            <div v-if="item.type == 2" class="typeOther showQy"></div>
            <el-image
                    v-if="item.pics"
                    :src="'/info-admin' + item.pics[0]"
                    style="width: 100%; height: 100%"
            ></el-image>
          </div>
          <div class="cont-info">
            <el-row class="mb10">
              <el-col :span="8">
                品名：<span class="proName" @click="toSeekDetail(item, 1)">{{ item.name }}</span>
              </el-col>
              <el-col :span="8">规格：{{ item.spec }} </el-col>
              <el-col :span="8">单价：{{ item.price }} </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8"> 数量：{{ item.buycount }}{{item.unit}} </el-col>
              <el-col :span="8">产地：{{ item.addr }} </el-col>
              <el-col :span="8">货源所在地：{{ item.addr }} </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8">
                发布：{{ item.publishdatetime ? item.publishdatetime.split(' ')[0] : '' }}</el-col
              >
              <el-col :span="8"
              >有效期：剩余{{ item.deadline }}天
              </el-col>
              <el-col :span="8" class="colorRed">
                <div class="smrz">
                  <div>实名认证：</div>
                  <img
                          style="height: 35px; text-align: center"
                          src="@/assets/imgs/smrz.png"
                          alt=""
                  />
                </div>
              </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="8" class="colorRed">
                联系人：{{ item.contacter }}</el-col
              >
              <el-col :span="8" class="colorRed"
              >手机：{{ item.contacttel }}
              </el-col>
              <el-col :span="8">
                <div class="xjly" @click="getComment(item)">询价留言</div>
              </el-col>
            </el-row>
            <el-row class="mb10">
              <el-col :span="24" class="colorRed">
                地址：{{ item.idcadeno }}</el-col
              >
            </el-row>
          </div>
        </div>
      </div>
      <!-- <div class="main_d2" v-if="productList.length > 0">
        <div class="mid-li" v-for="(item, index) in productList" :key="index">
          <div style="width: 158px; height: 158px;" @click="toDetail(item, item.goodtype)">
            <el-carousel
              trigger="click"
              height="158px"
              v-if="item.picsurl"
            >
              <el-carousel-item v-for="(item, index) in item.picsurl.split(',')" :key="index">
                <img style="width: 158px; height: 158px;" :src="'/info-admin' + item" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <h2 class="proName" @click="toDetail(item, item.goodtype)">
            {{ item.name }}
          </h2>
          <p>规格：{{ item.spec }}</p>
          <p>数量：{{ item.stockcount }}</p>
          <p>产地：{{ item.addr }}</p>
          <p>价格：{{ item.price }}</p>
          <p>剩余：{{ item.deadline }}天</p>
        </div>
      </div> -->
    </div>
    <el-dialog
            title="询价留言"
            :visible.sync="dialogFormVisible"
            :show-close="false"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-row>
          <el-col :span="22">
            <el-form-item label="标题" prop="title">
              <el-input
                      class="dialog-input"
                      v-model="form.title"
                      autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="内容" prop="content">
              <el-input
                      v-model="form.content"
                      type="textarea"
                      :rows="5"
                      autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="联系方式" prop="phoneNum">
              <el-input
                      class="dialog-input"
                      v-model="form.phoneNum"
                      autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submitComment">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button class="footer-sub" @click="submitComment" :disabled="isDisable">确认</el-button>
        <el-button class="footer-del" @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getSupplyInfo, getPurchaseInfo, getById, messageSave, getAdviceGoods } from "@/api/modules/gq";
  import FromRules from '@/utils/fromRules'
  import { saveView } from "@/api/modules/index";
  export default {
    name: 'ghDetail',
    data () {
      return {
        isDisable: false, //禁用确认按钮
        info: {},
        parlourForm: {},
        dialogFormVisible: false,
        form: {
          title: '',
          content: '',
          phoneNum: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          content: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          phoneNum: [
            { required: true, message: '请输入联系方式', trigger: 'change' },
            { validator: FromRules.validatePhone, trigger: 'change' },
          ],
        },
        productList: [],
      }

    },
    created () {
      this.getInfo()
    },
    methods: {
      goback() {
        this.$router.go(-1)
        /*this.$router.push({
          name: 'gq',
          query: {
            param: this.$route.query.purchaseType?'second':'first',
          },
        })*/
      },
      // 推荐信息
      getDetail () {
        const form = {
          publisherUsername: this.info.publisher_username,
          type: this.$route.query.type,
          mname: this.info.name,
          dataId: this.$route.query.id
        }
        getAdviceGoods(form).then(res => {
          if (res.code == 200) {
            this.productList = res.data
            if(this.$route.query.type == 0) {
              this.productList.forEach(e => {
                if (e.picsurl && e.picsurl.length >0) {
                  e.picsurl = e.picsurl.split(',')
                }
              });
            }else if(this.$route.query.type == 1) {
              this.productList.forEach(e => {
                if (e.pics && e.pics.length >0) {
                  e.pics = e.pics.split(',')
                }
              });
            }
          }
        })
      },
      toDetail (item, type) {
        saveView({
          viewType: 0,
          relateId: item.id,
          type: type
        }).then((res) => { });
        this.$router.push({
          name: "gqDetail",
          query: {
            id: item.id,
            type: type
          },
        });
        this.getInfo()
      },
      toSeekDetail (item, type) {
        saveView({
          viewType: 3,
          relateId: item.id,
          type: type
        }).then((res) => { });
        this.$router.push({
          name: "gqDetail",
          query: {
            id: item.id,
            type: type,
            purchaseType: item.type
          },
        });
        this.getInfo()
      },
      getInfo () {
        if (this.$route.query.type == 1) {
          getPurchaseInfo({ id: this.$route.query.id }).then(res => {
            this.info = res.data
            this.getDetail()
            if (this.info.pics) {
              this.info.pics = this.info.pics.split(',')
            }
            getById({ username: res.data.publisher_username }).then(res => {
              if (res.code == 200) {
                this.parlourForm = res.data
              }
            })
          })
        } else {
          getSupplyInfo({ id: this.$route.query.id }).then(res => {
            this.info = res.data
            this.getDetail()
            if (this.info.picsurl) {
              this.info.picsurl = this.info.picsurl.split(',')
            }
            console.log(this.info, 222)
            getById({ username: res.data.publisher_username }).then(res => {
              if (res.code == 200) {
                this.parlourForm = res.data
              }
            })
          })
        }


      },
      getComment () {
        this.dialogFormVisible = true
        this.isDisable = false
      },
      submitComment () {
        const query = {
          title: this.form.title,
          content: this.form.content,
          phoneNum: this.form.phoneNum,
          pId: this.$route.query.id,
          type: this.$route.query.type
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            messageSave(query).then(res => {
              if (res.code == 200) {
                this.dialogFormVisible = false
                this.$message.success('留言成功')
                this.isDisable = true
              }
            })
          }
        })
      },
      toMyShop () {
        if (this.parlourForm.id) {
          saveView({
            viewType: 1,
            relateId: this.parlourForm.id,
          }).then((res) => { });
        }



        this.$router.push({
          name: "myShop",
          query: {
            username: this.info.publisher_username,
            id: this.$route.query.id,
            type: this.$route.query.type,
            purchaseType: this.$route.query.purchaseType,
          },
        });

      },
    }
  }
</script>

<style lang="less" scoped>
  .gq-detail {
    max-width: 1199px;
    margin: 40px auto 0;
    padding-bottom: 40px;

    .goback {
      padding: 10px;
    }

    ::v-deep .el-form-item__label {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }

    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .el-dialog__header {
      text-align: center;
      height: 76px;
      background: #1b86fb;
      padding: 0;
      line-height: 76px;
      margin: 0 auto;
    }
    ::v-deep .el-dialog__body {
      background: #f9f8f8;
    }
    ::v-deep .el-dialog__footer {
      text-align: center;
      background: #f9f8f8;
    }

    ::v-deep .el-dialog__title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .tp {
      border-bottom: none !important;
    }

    .select {
      margin: auto;
      height: 22px;

      &-item {
        display: flex;
        align-items: center;
        line-height: 42px;
        border: 1px solid #eee;
        padding: 10px 0;

        div {
          font-size: 16px;
          padding: 0px 10px;
          margin-right: 14px;
          cursor: pointer;
        }

        div:first-child {
          margin-right: 0;
        }

        .active {
          background: rgb(20, 99, 158);
          border-radius: 4px;
          color: #fff;
        }
      }
    }
  }
  .content-module {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    .cont-mid {
      width: 473px;
      margin-right: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      h3 {
        margin-bottom: 10px;
        height: 45px;
        color: #333333;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
      }
      .notice {
        width: 473px;
        height: 45px;
        background: #fafafa;
        border: 1px solid #dedede;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 45px;
        padding-left: 10px;
        margin-bottom: 10px;
      }
      ul {
        width: 473px;
        display: flex;
        flex-wrap: wrap;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        li {
          width: 50%;
          display: flex;
          font-size: 18px;
          font-weight: 500;
          color: #4f5156;
          line-height: 25px;
          padding: 10px 0;

          span {
            display: inline-block;
            min-width: 90px;
          }
        }
      }
      .mid-bottom {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        .left-btn {
          cursor: pointer;
          width: 144px;
          height: 60px;
          background: #ff7701;
          border-radius: 6px;
          border: 2px solid #ff7701;
        }
        .right-btn {
          cursor: pointer;
          width: 144px;
          height: 60px;
          background: #1b86fb;
          border-radius: 6px;
          border: 2px solid #1b86fb;
        }
      }
    }
    .cont-right {
      margin-top: 55px;
      width: 326px;
      height: 404px;
      border: 1px solid #dedede;
      background: #fafafa;
      h3 {
        border-bottom: 1px solid #ff9000;
        height: 62px;
        line-height: 62px;
        padding-left: 20px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff9000;
      }
      .home-img {
        height: 31px;
        vertical-align: middle;
        margin-right: 10px;
      }
      ul {
        padding-left: 20px;
        li {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4f5156;
          line-height: 22px;
          padding-top: 15px;
        }
      }
    }
  }
  .content-body {
    h3 {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid #dedede;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }
    .body-contnet {
      // width: 100%;
      height: 280px;
      background: #ffffff;
      border: 1px solid #dedede;
      overflow-y: auto;
      padding: 40px;
      margin-bottom: 40px;
    }
    .content-module-tuijian {
      .content-div {
        width: 100%;
        height: 205px;
        display: flex;
        border-bottom: 1px solid #cfcfcf;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 20px;

        .img {
          width: 204px;
          height: 205px;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          .showTj {
            left: 0;
            background: url(../../../assets/imgs/tuijian.png) no-repeat;
          }
          .showJs {
            left: 0;
            background: url(../../../assets/imgs/jishou.png) no-repeat;
          }
          .showZj {
            right: 0;
            background: url(../../../assets/imgs/jian.png) no-repeat;
          }
          .showYx {
            left: 0;
            background: url(../../../assets/imgs/youxian.png) no-repeat;
          }
          .showGr {
            right: 0;
            background: url(../../../assets/imgs/grqg.png) no-repeat;
          }
          .showQy {
            right: 0;
            background: url(../../../assets/imgs/qyqg.png) no-repeat;
          }
          .type {
            position: absolute;
            top: 0;
            width: 46px;
            height: 46px;
            z-index: 999;
          }
          .typeOther {
            position: absolute;
            top: 0;
            width: 118px;
            height: 65px;
            z-index: 999;
          }
        }
        .cont-info {
          // width: 628px;
          flex: 1;
          .smrz {
            width: 100%;
            height: 35px;
            line-height: 35px;
            display: flex;
          }
          .xjly {
            text-align: center;
            width: 102px;
            height: 35px;
            background: #eeeeee;
            border-radius: 2px;
            border: 1px solid #cfcfcf;

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 35px;
          }
          .el-row {
            height: 35px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #4f5156;
            line-height: 35px;
          }
          .mb10 {
            margin-bottom: 10px;
          }
          .colorRed {
            color: red;
          }
          .text {
            text-align: left;
            margin-bottom: 20px;
            overflow: hidden;
          }
        }
      }
      .content-qy {
        width: 100%;
        border-bottom: 1px solid #cfcfcf;
        padding: 40px 0 20px;
        cursor: pointer;
        .qy-title {
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4f5156;
          line-height: 28px;
        }
        .qy-time {
          text-align: right;
          margin-top: 20px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4f5156;
          line-height: 25px;
        }
      }
    }
    .main_d2 {
      margin-top: 1px;
      padding-top: 20px;
      border: 1px solid #dedede;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      // grid-column-gap: 40px;
      // grid-row-gap: 34px;
      // padding-right: 10px;
      .mid-li {
        width: 150px;
        height: auto;
        margin: 13px 0 10px 13px;
        h2 {
          cursor: pointer;
          width: 150px;
          height: 24px;
          line-height: 24px;
          margin-top: 3px;
          &:hover {
            outline: 0;
            text-decoration: underline;
          }
        }
        p {
          font: normal 12px/22px '宋体';
          color: #333;
        }
      }
    }
  }
  .mr21 {
    width: 360px;
    height: 360px;
    margin-right: 21px;
    .banner-img {
      width: 100%;
    }
  }
  .m10 {
    margin: 10px 0;
  }
  .mt10 {
    margin-top: 10px;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 60px;
    .footer-sub {
      width: 144px;
      height: 60px;
      background: #1b86fb;
      border-radius: 6px;
      color: #ffffff;
      border: 2px solid #1b86fb;
      margin-right: 185px;
    }
    .footer-del {
      width: 144px;
      height: 60px;
      border-radius: 6px;
      border: 2px solid #1b86fb;
      color: #1b86fb;
    }
    .dialog-input input {
      height: 45px;
      background: #ffffff;
      border: 1px solid #dedede;
    }
  }
</style>