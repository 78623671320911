<template>
  <div class="bid-management">
    <div class="tabs">
      <el-tabs class="tabs-list" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane label="已发起" name="0"></el-tab-pane>
        <el-tab-pane label="开标中" name="1"></el-tab-pane>
        <el-tab-pane label="二次报价" name="2"></el-tab-pane>
        <el-tab-pane label="评审中" name="3"></el-tab-pane>
        <el-tab-pane label="已中标" name="4"></el-tab-pane>
        <el-tab-pane label="已结束" name="8"></el-tab-pane>
      </el-tabs>
    </div>
    <ul class="bid-list">
      <li
        class="card"
        v-for="(item, index) in dataList"
        :key="index"
        @click="detailBtn(item.id)"
      >
        <div class="card-title">
          <span class="l-style"></span><span>{{ item.conpanyname }}</span>
        </div>
        <ul class="card-con">
          <li class="card-con-item">
            <div class="item1">付款方式： {{ paymentWatch(item.payment) }}</div>
            <div class="item1">截止时间：{{ item.deadlinedate }}</div>
            <div class="item1">状态：{{ item.bidStatus }}</div>
            <div class="item1">交货地：{{ item.plaadder }}</div>
          </li>
          <li class="card-con-item">
            <div class="item1">招标物料：{{ item.materialnamestr }}</div>
            <div class="item1"></div>
            <div class="item1"></div>
            <div class="item1"></div>
          </li>
          <li class="card-con-item">
            <div class="card-con-imgs">
              <el-image
                class="images-style"
                v-for="url in item.pics"
                :key="url"
                :src="url"
                lazy
                :preview-src-list="item.pics"
              ></el-image>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <el-pagination
      class="pagination-style"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="pagination.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getMyInquery } from '@/api/modules/bid'
import { getDictInfo } from '@/api/modules'
export default {
  created() {
    //this.getMyInqueryMaterials()
    this.getMyInquery()
  },
  data() {
    return {
      activeName: '-1',
      dataList: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
      urls: [],
      form: {
        name: '',
        qstatus: -1,
        payment: 2,
      },
      payment: [],
      dataList1: [],
    }
  },

  computed: {
    paymentWatch() {
      return function (bidStatus) {
        if (bidStatus == null) {
          return ''
        }

        let paymentFind = this.payment.find((item) => item.value == bidStatus)
        return paymentFind ? paymentFind.label : ''
      }
    },
  },
  mounted() {
    this.getPayment()
  },
  methods: {
    getPayment() {
      getDictInfo('BID_PAY_TYPE').then((res) => {
        console.log(res, '2121212121')
        if (res.code == 200) {
          const map = new Map(Object.entries(res.data))
          this.payment = this.mapToObj(map)
        }
      })
    },

    mapToObj(strMap) {
      let arr = new Array()
      for (let [k, v] of strMap) {
        let obj = Object.create(null)
        obj.value = parseInt(k)
        obj.label = v
        arr.push(obj)
      }
      return arr
    },
    detailBtn(inquiryId) {
      console.log('inquiryId')
      console.log(inquiryId)
      this.$router.push({
        name: 'BidDetail',
        query: {
          id: inquiryId,
        },
      })
    },
    getMyInqueryMaterials() {
      let that = this
      getMyInqueryMaterials({
        name: that.form.name,
        qstatus: that.form.qstatus,
        payment: that.form.payment,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size,
      }).then((res) => {
        //console.log(res)
        let datas = res.data.content
        //let vals = [];
        for (var i = 0; i < datas.length; i++) {
          // console.log(datas[i]);
          if (datas[i].pics != null) {
            datas[i].pics = datas[i].pics.split(',')
          } else {
            datas[i].pics = []
          }
          //vals.push(datas[i].deadline)
        }
        //console.log(vals)
        // that.countdown(vals);
        console.log('datas')
        console.log(datas)
        that.dataList = datas
        that.pagination.total = res.data.totalElements
        that.pagination.size = res.data.size
        that.pagination.page = res.data.number + 1
      })
    },

    getMyInquery() {
      let that = this
      getMyInquery({
        qstatus: that.form.qstatus,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size,
      }).then((res) => {
        let datas = res.data.content
        console.log('datas')
        console.log(datas)
        for (var i = 0; i < datas.length; i++) {
          switch (
            datas[i].bidStatus //0:投标，1:已经开标 2:议价中 3:议价结束 4:已中标
          ) {
            case 0:
              datas[i].bidStatus = '已发起'
              break
            case 1:
              datas[i].bidStatus = '开标中'
              break
            case 2:
              datas[i].bidStatus = '二次报价'
              break
            case 3:
              datas[i].bidStatus = '评审中'
              break
            case 4:
              datas[i].bidStatus = '已中标'
              break
            case 5:
              datas[i].bidStatus = '招标审核中'
              break
            case 6:
              datas[i].bidStatus = '招标审核不通过'
              break
            case 7:
              datas[i].bidStatus = '投标中'
              break
            case 8:
              datas[i].bidStatus = '已结束'
              break
            case -1:
              datas[i].bidStatus = '审核不通过'
              break
            case -2:
              datas[i].bidStatus = '流标'
              break
            case -3:
              datas[i].bidStatus = '废标'
              break
          }
          datas[i].deadlinedate = datas[i].deadlinedate.split('T')[0]
        }
        that.dataList = datas
        that.pagination.total = res.data.totalElements
        that.pagination.size = res.data.size
        that.pagination.page = res.data.number + 1
      })
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.getMyInquery()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getMyInquery()
    },
    handleClick(tab, event) {
      console.log(tab, event, '0.0.0.0')
      this.form.qstatus = tab.name
      this.getMyInquery()

      //console.log(tab.name)
    },
    formatDate(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          )
        }
      }
      return fmt
    },
  },
}
</script>

<style lang="less" scoped>
.bid-management {
  max-width: 1100px;
  margin: auto;
  .tabs {
    background-color: #fff;
    padding: 20px 0;
    .tabs-list {
      max-width: 1000px;
      margin: auto;
      padding: 0 20px;
    }
    /deep/.el-tabs__header {
      margin-bottom: 0;
    }
    /deep/.el-tabs__item.is-active {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__item:hover {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(20, 99, 158, 1);
    }
  }
  .bid-list {
    .card:hover {
      box-shadow: 0 0 10px rgba(20, 99, 158, 0.3);
    }
    .card {
      cursor: pointer;
      box-shadow: 0 0 10px #ddd;
      border-radius: 8px;
      padding: 20px 20px 10px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      &-title {
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .l-style {
          display: inline-block;
          width: 10px;
          height: 18px;
          background: rgb(20, 99, 158);
          margin-right: 6px;
          border-radius: 4px;
        }
        .count-down {
          width: 26%;
          margin-left: auto;
          text-align: center;
          span {
            color: red;
          }
        }
      }
      &-con {
        font-size: 14px;
        width: 100%;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
          .item1 {
            width: calc((100% - 100px) / 2);
          }
          // .item1:last-child{
          //     width: 200px;
          // }
          .item2 {
            position: relative;
            .btns {
              position: absolute;
              font-size: 16px;
              padding: 4px 16px;
              border: 1px solid rgb(20, 99, 158);
              border-radius: 4px;
              top: -10px;
              right: 0;
              cursor: pointer;
              color: rgb(20, 99, 158);
            }
            .btns:hover {
              background-color: rgba(20, 99, 158, 0.1);
            }
          }
          .item3 {
            color: #999;
            cursor: pointer;
          }
        }
      }
      &-con-right {
        width: 20%;
        text-align: center;
        &-img {
          width: 100px;
          height: 100px;
          margin: 10px auto 0;
          display: block;
        }
        &-value {
          font-size: 12px;
        }
      }
      &-con-imgs {
        width: 100%;
        .con-imgs-item {
          width: 50px;
          height: 50px;
          margin: 0 4px;
        }
      }
      .images-style {
        width: 10%;
        padding: 4px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }
  }
  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
