<template>
  <div class="my-comment">
    <el-table :data="tableData" stripe style="width: 100%">
      <!-- <el-table-column prop="type" label="类型" min-width="100px">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? '求购' : '供应' }}
        </template>
      </el-table-column> -->

      <el-table-column prop="materialName" label="品名" min-width="120px">
      </el-table-column>
      <el-table-column prop="title" label="标题" min-width="120px">
      </el-table-column>
      <el-table-column prop="phoneNum" label="手机号码" min-width="160px">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="200px">
      </el-table-column>
      <el-table-column prop="content" label="内容" min-width="200px">
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination-style"
      @size-change="handleSize"
      @current-change="handleCurrent"
      :current-page="queryParams.current"
      :page-sizes="[1, 10, 20, 50, 100]"
      :page-size="queryParams.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="this.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { limit } from "@/api/modules/comment";
import {
  getUserInfo
} from '@/api/modules'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        current: 1,
        size: 10,
        userName: ''
      }
    }
  },

  created () {
    this.getList()
  },
  methods: {
    getList () {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.queryParams.userName = res.data.personalphone
          limit(this.queryParams).then(res => {
            if (res.code == 200) {
              this.tableData = res.data
              this.total = res.total
            }
          })
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })

    },
    handleSize (val) {
      this.queryParams.size = val;
      this.queryParams.current = 1;
      this.getList();
    },
    handleCurrent (val) {
      this.queryParams.current = val;
      this.getList();
    },
  }
}
</script>

<style lang="less" scoped>
.my-comment {
  max-width: 1190px;
  margin: 50px auto 0;
  padding-bottom: 40px;
}
.pagination-style {
  text-align: right;
  margin-bottom: 20px;
  background: #fff;
  padding: 16px 0;
  padding-right: 20px;
  // border-bottom-left-radius:5px;
  //  border-bottom-right-radius:5px;
}
</style>
