const prefix = process.env.VUE_APP_LOCAL_API

export default ({ request }) => ({
  // 首页轮播-列表
  getBannerPage(params = {}) {
    return request({
      url: prefix + '/banner/getBannerPage',
      method: 'get',
      params
    })
  },
  // 首页轮播-新增
  addBanner(data = {}) {
    return request({
      url: prefix + '/banner/addBanner',
      method: 'post',
      data
    })
  },
  // 首页轮播-修改、删除
  updateBanner(data = {}) {
    return request({
      url: prefix + '/banner/updateBanner',
      method: 'post',
      data
    })
  },
  // 集团介绍-列表
  getGroupIntroductionPage(params = {}) {
    return request({
      url: prefix + '/groupIntroduction/getGroupIntroductionPage',
      method: 'get',
      params
    })
  },
  // 集团介绍-新增
  addGroupIntroduction(data = {}) {
    return request({
      url: prefix + '/groupIntroduction/addGroupIntroduction',
      method: 'post',
      data
    })
  },
  // 集团介绍-修改、删除
  updateGroupIntroduction(data = {}) {
    return request({
      url: prefix + '/groupIntroduction/updateGroupIntroduction',
      method: 'post',
      data
    })
  },
  // 公司业务-列表
  getCorporateBusinessPage(params = {}) {
    return request({
      url: prefix + '/corporateBusiness/getCorporateBusinessPage',
      method: 'get',
      params
    })
  },
  // 公司业务-新增
  addCorporateBusiness(data = {}) {
    return request({
      url: prefix + '/corporateBusiness/addCorporateBusiness',
      method: 'post',
      data
    })
  },
  // 公司业务-修改、删除
  updateCorporateBusiness(data = {}) {
    return request({
      url: prefix + '/corporateBusiness/updateCorporateBusiness',
      method: 'post',
      data
    })
  },
  // 产品展示-列表
  getProductDisplayPage(params = {}) {
    return request({
      url: prefix + '/productDisplay/getProductDisplayPage',
      method: 'get',
      params
    })
  },
  // 产品展示-新增
  addProductDisplay(data = {}) {
    return request({
      url: prefix + '/productDisplay/addProductDisplay',
      method: 'post',
      data
    })
  },
  // 产品展示-修改、删除
  updateProductDisplay(data = {}) {
    return request({
      url: prefix + '/productDisplay/updateProductDisplay',
      method: 'post',
      data
    })
  },
  // 合作单位-列表
  getCooperatorCompanyPage(params = {}) {
    return request({
      url: prefix + '/cooperatorCompany/getCooperatorCompanyPage',
      method: 'get',
      params
    })
  },
  // 合作单位-新增
  addCooperatorCompany(data = {}) {
    return request({
      url: prefix + '/cooperatorCompany/addCooperatorCompany',
      method: 'post',
      data
    })
  },
  // 合作单位-修改、删除
  updateCooperatorCompany(data = {}) {
    return request({
      url: prefix + '/cooperatorCompany/updateCooperatorCompany',
      method: 'post',
      data
    })
  },
  // 发展历程-列表
  getDevelopmentHistoryPage(params = {}) {
    return request({
      url: prefix + '/developmentHistory/getDevelopmentHistoryPage',
      method: 'get',
      params
    })
  },
  // 发展历程-新增
  addDevelopmentHistory(data = {}) {
    return request({
      url: prefix + '/developmentHistory/addDevelopmentHistory',
      method: 'post',
      data
    })
  },
  // 发展历程-修改、删除
  updateDevelopmentHistory(data = {}) {
    return request({
      url: prefix + '/developmentHistory/updateDevelopmentHistory',
      method: 'post',
      data
    })
  },
  // 上传图片
  uploadImg() {
    return prefix + '/uploadImg'
  }
})
