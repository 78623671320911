<template>
  <div class="banner">
    <img src="@/assets/imgs/bj.png">
    <div class="w">
      <div class="words">
        <h1 class="h1" :class="title?'underline':''">{{title}}</h1>
        <section v-if="isServiceHall" class="settle-in">
          <el-button @click="settleIn()" type="primary">商家入驻</el-button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { getUserInfo, logOff } from '@/api/modules'
export default {
  mixins: [],
  props: {
    title: {
      type: String
    },
    titleType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      isServiceHall: false,
      checkFlag: -2,
      userInfo: '',
    }
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    const router = this.$router;
    (router.history.current.name === 'ServiceHall') && (this.isServiceHall = true);
  },
  methods: {
    toLogin() {
      this.$router.push('/login')
    },
    settleIn() {
      //window.open('https://test.boyaocaigou.com/#/joinApply', '_blank');
      //this.$router.push('/joinApply')

       // console.log("aaaaaaaaaaaa:"+this.userInfo.nickname);
      // 点击入驻申请，若我的资质未填写或者未审核通过，则跳转我的资质界面，若审核通过跳转企业维护界面
      if (!this.userInfo.nickname) {
        this.toLogin()
        return
      }

      // 审核通过
      setTimeout(() => {
        this.$router.push({
          name: 'joinApply'
        })
      }, 1000)

      // getUserInfo().then((res) => {
      //   if (res.code == 200) {
      //     this.checkFlag = res.data.checkFlag
      //     console.log(this.checkFlag)
      //     if (this.checkFlag == -1) {
      //       this.$message.warning('您还没有认证资质，请认证资质哦！')
      //       setTimeout(() => {
      //         this.$router.push({
      //           name: 'qualifications'
      //         })
      //       }, 1000)
      //       return
      //     } else if (this.checkFlag == 1) {
      //       // 审核通过
      //       setTimeout(() => {
      //         this.$router.push({
      //           name: 'joinApply'
      //         })
      //       }, 1000)
      //       return
      //     } else if (this.checkFlag == 0) {
      //       this.$message.warning('您的资质正在审核中，请等候！')
      //       setTimeout(() => {
      //         this.$router.push({
      //           name: 'qualifications'
      //         })
      //       }, 1000)
      //       return
      //     } else if (this.checkFlag == 2) {
      //       this.$message.warning('您的资质认证未通过，请重新认证！')
      //       setTimeout(() => {
      //         this.$router.push({
      //           name: 'qualifications'
      //         })
      //       }, 1000)
      //       return
      //     }
      //   }
      // })


    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.$store.commit('userInfo', res.data || {})

        if (res.code == 200) {
          this.checkFlag = res.data.checkFlag
          this.userInfo = res.data
        }
      })
    },

  }
}
</script>

<style lang='scss' scoped>
.banner {
  .w {
    position: relative;
    z-index: 2;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .words {
    .h1 {
      font-size: 36px;
      padding-bottom: 20px;
      position: relative;
    }
    .settle-in {
      width: 1200px;
      height: 228px;
      margin: 0 auto;
      position: relative;
      .el-button--primary, .el-button--primary:focus, .el-button--primary:hover {
          background-color: #0D53FF;
          border-color: #0D53FF;
          border-radius: 17px;
          position: absolute;
          top: 5%;
          left: 50%;
          transform: translate(-50%, 0);
      }
    }
    .underline::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 16px;
      height: 4px;
      background-color: #0054e4;
    }
  }
}
</style>
