<template>
  <div class="release">
    <el-tabs
      class="tabs-content"
      v-model="activeName"
      @tab-click="handleClick"
      type="border-card"
    >
      <el-tab-pane label="供应管理" name="first">
        <el-row :gutter="10" class="mb10">
          <el-col :span="1.5">
            <el-button
              type="primary"
              size="small"
              plain
              icon="el-icon-plus"
              @click="gyAdd"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="gyTableData" border style="width: 100%">
          <el-table-column type="index" label="序号" width="60px">
          </el-table-column>
          <el-table-column prop="name" label="药材名称"> </el-table-column>
          <el-table-column prop="spec" label="规格" width="70px">
          </el-table-column>
          <el-table-column prop="stockcount" label="库存量" width="70px">
          </el-table-column>
          <el-table-column prop="addr" label="药材产地"> </el-table-column>
          <el-table-column prop="stockaddr" label="库存地" width="70px">
          </el-table-column>
          <el-table-column prop="unit" label="单位" width="70px"> </el-table-column>
          <el-table-column prop="price" label="价格" width="110px">
          </el-table-column>
          <el-table-column prop="contacter" label="联系人"> </el-table-column>
          <el-table-column prop="contacttel" label="联系电话" min-width="110px">
          </el-table-column>
          <el-table-column prop="checkflag" label="审核状态">
            <template v-slot="scope">
              <!-- 1、待审核  2、审核通过 -1 审核不通过 -->
              <span v-if="scope.row.checkflag == 1 || scope.row.checkflag == null">待审核</span>
              <span v-if="scope.row.checkflag == 2">审核通过</span>
              <span v-if="scope.row.checkflag == -1">审核不通过</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @size-change="gySizeChange"
          @current-change="gyCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          :current-page="queryParams.page"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="gyTotal"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="个人求购" name="second">
        <el-row :gutter="10" class="mb10">
          <el-col :span="1.5">
            <el-button
              type="primary"
              size="small"
              plain
              icon="el-icon-plus"
              @click="qgAdd"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="qgTableData" border style="width: 100%">
          <el-table-column type="index" label="序号" width="60px">
          </el-table-column>
          <el-table-column prop="name" label="药材名称"> </el-table-column>
          <el-table-column prop="spec" label="规格" width="70px"> </el-table-column>
          <el-table-column prop="buycount" label="采购数量" width="80px">
          </el-table-column>
          <el-table-column prop="unit" label="单位" width="70px"> </el-table-column>
          <el-table-column prop="price" label="价格" width="110px">
          </el-table-column>
          <el-table-column prop="addr" label="药材产地"> </el-table-column>
          <el-table-column prop="contacter" label="联系人"> </el-table-column>
          <el-table-column prop="contacttel" label="联系电话" min-width="110px">
          </el-table-column>
          <el-table-column prop="publishdatetime" label="发布时间"> </el-table-column>
          <el-table-column prop="checkflag" label="审核状态">
            <template v-slot="scope">
              <!-- 1、待审核  2、审核通过 -1 审核不通过 -->
              <span v-if="scope.row.checkflag == 1 || scope.row.checkflag == null">待审核</span>
              <span v-if="scope.row.checkflag == 2">审核通过</span>
              <span v-if="scope.row.checkflag == -1">审核不通过</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @size-change="qgSizeChange"
          @current-change="qgCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          :current-page="qgParams.page"
          :page-size="qgParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="qgTotal"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="企业求购" name="third">
        <el-row :gutter="10" class="mb10">
          <el-col :span="1.5">
            <el-button
              type="primary"
              size="small"
              plain
              icon="el-icon-plus"
              @click="qyAdd"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <el-table :data="qyTableData" border style="width: 100%">
          <el-table-column type="index" label="序号" width="60px">
          </el-table-column>
          <el-table-column prop="company" label="公司名称" min-width="100px">
          </el-table-column>
          <el-table-column prop="name" label="药材名称"> </el-table-column>
          <el-table-column prop="spec" label="规格" width="70px"> </el-table-column>
          <el-table-column prop="buycount" label="采购数量" width="80px">
          </el-table-column>
          <el-table-column prop="unit" label="单位" width="70px"> </el-table-column>
          <el-table-column prop="price" label="价格" width="110px">
          </el-table-column>
          <el-table-column prop="addr" label="药材产地"> </el-table-column>
          <el-table-column prop="contacter" label="联系人"> </el-table-column>
          <el-table-column prop="contacttel" label="联系电话" min-width="110px">
          </el-table-column>
          <el-table-column prop="publishdatetime" label="发布时间"> </el-table-column>
          <el-table-column prop="checkflag" label="审核状态">
            <template v-slot="scope">
              <!-- 1、待审核  2、审核通过 -1 审核不通过 -->
              <span v-if="scope.row.checkflag == 1 || scope.row.checkflag == null">待审核</span>
              <span v-if="scope.row.checkflag == 2">审核通过</span>
              <span v-if="scope.row.checkflag == -1">审核不通过</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @size-change="qySizeChange"
          @current-change="qyCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          :current-page="qyParams.page"
          :page-size="qyParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="qyTotal"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="title"
      width="60%"
      :visible.sync="dialogFormVisible"
      @close="closeDialog"
    >
      <el-form
        class="form-card"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="24" v-if="activeName == 'third'">
            <el-form-item label="公司名称">
              <el-input v-model="form.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="activeName == 'third'">
            <el-form-item label="公司地址">
              <el-input v-model="form.companyAddress"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品名" prop="name">
              <div class="df">
                <el-input style="width: 80%" v-model="form.name" :disabled="true"></el-input>
                <el-button
                  style="background: #ff5722; color: #fff;text-align: center;height: 40px;"
                  @click="getTable"
                  >选择</el-button
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格">
              <el-select v-model="form.spec" style="width: 100%">
                <el-option
                  v-for="(item, index) in otherList.spec"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="activeName != 'first'">
            <el-form-item label="采购数量" prop="buycount">
              <el-input-number
                v-model="form.buycount"
                style="width: 100%"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="activeName == 'first'">
            <el-form-item label="库存量" prop="stockcount">
              <el-input v-model="form.stockcount"></el-input>
            </el-form-item>
          </el-col> 
          <el-col :span="8">
            <el-form-item label="药材产地">
              <el-select v-model="form.addr" style="width: 100%">
                <el-option
                  v-for="(item, index) in otherList.address"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="价格" prop="price">
              <el-input-number
                v-model="form.price"
                style="width: 70%"
              ></el-input-number>
              <el-select v-model="form.priceUnit" style="width: 30%">
                <el-option
                  v-for="(item, index) in priceUnitList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位">
              <el-select v-model="form.unit" style="width: 100%">
                <el-option
                  v-for="(item, index) in otherList.unit"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质量标准">
              <el-select v-model="form.standard" style="width: 100%">
                <el-option
                  v-for="(item, index) in otherList.standard"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="activeName != 'first'">
            <el-form-item label="货源所在地">
              <!-- <el-input v-model="form.stockaddr"></el-input> -->
              <el-select v-model="form.province" style="width: 33%" @change="changeProvince">
                <el-option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.city" style="width: 33%" @change="changeCity">
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.area" style="width: 33%" @change="changeArea">
                <el-option
                  v-for="(item, index) in areaList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="库存地" v-if="activeName == 'first'">
              <!-- <el-input v-model="form.deliveryaddr"></el-input> -->
              <el-select v-model="form.province" style="width: 33%" @change="changeProvince">
                <el-option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.city" style="width: 33%" @change="changeCity">
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.area" style="width: 33%" @change="changeArea">
                <el-option
                  v-for="(item, index) in areaList"
                  :key="index"
                  :label="item.xzqhmc"
                  :value="item.xzqhdm"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细地址">
              <el-input v-model="form.idcadeno"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分类">
              <el-select v-model="form.drugType" style="width: 100%">
                <el-option
                  v-for="(item, index) in mcateData"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人">
              <el-input v-model="form.contacter"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="contacttel">
              <el-input
                v-model="form.contacttel"
                oninput ="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="截止日期" prop="deadline">
              <el-date-picker
                style="width: 100%"
                v-model="form.deadline"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否付费" v-if="activeName == 'third'" prop="free">
              <el-select v-model="form.free" style="width: 100%">
                <el-option label="付费" value="2"> </el-option>
                <el-option label="免费" value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="activeName == 'third' && this.form.free == '2'">
            <el-form-item label="费用">
              <el-input v-model="form.fee"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="activeName == 'first'">
            <el-form-item label="产品图">
              <el-upload
                ref="upload1"
                class="upload-demo"
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                action="#"
                :http-request="handleIdcardFileUpload"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <span
                  slot="tip"
                  class="el-upload__tip"
                  style="margin-left: 15px"
                  >大小2MB内的jpg/png文件</span
                >
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="activeName != 'first'">
            <el-form-item label="商品图片">
              <el-upload
                ref="upload2"
                class="upload-demo"
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                action="#"
                :http-request="handleIdcardFileUploadQg"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <span
                  slot="tip"
                  class="el-upload__tip"
                  style="margin-left: 15px"
                  >大小2MB内的jpg/png文件</span
                >
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="质检图片" v-if="activeName == 'first'">
              <el-upload
                ref="upload3"
                class="upload-demo"
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                action="#"
                :http-request="handleIdcardFileUpload2"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <span
                  slot="tip"
                  class="el-upload__tip"
                  style="margin-left: 15px"
                  >大小2MB内的jpg/png文件</span
                >
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input
                type="textarea"
                :rows="4"
                v-model="form.descriptions"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="药品" :visible.sync="gridVisible" @close="closeGrid">
      <el-form label-width="100px" :inline="true">
        <!-- <el-row>
          <el-col span="8"> -->
        <el-form-item label="搜索名称" prop="materialname">
          <el-input
            style="width: 200px"
            v-model="queryParams.materialname"
            placeholder="请输入"
            maxlength="20"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <!-- </el-col>
          <el-col span="8"> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
        <!-- </el-col>
        </el-row> -->
      </el-form>
      <el-row :gutter="10" class="mb10">
        <el-col :span="1.5">
          <el-button type="primary" @click="handleAddName">确定</el-button>
        </el-col>
      </el-row>
      <el-table
        ref="nameTable"
        :data="nameOptions"
        border
        highlight-current-row
        @current-change="getSelected"
        @row-click="rowClick"
      >
        <el-table-column width="60px">
          <template v-slot="scope">
            <!-- label值要与el-table数据id实现绑定 -->
            <el-radio
              v-model="radio"
              :label="scope.row.index"
              @change="handleRowChange(scope.row)"
              >{{ '' }}</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" width="60px">
          <template slot-scope="scop">
            {{ queryParams.limit * (queryParams.page - 1) + scop.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column property="materialname" label="品名"></el-table-column>
        <el-table-column property="address" label="产地"></el-table-column>
        <el-table-column property="spec" label="规格"></el-table-column>
        <el-table-column
          property="standards"
          label="标准"
          min-width="200px"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        :current-page="queryParams.page"
        :page-size="queryParams.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { getAllMcate } from "@/api/modules/price";
import { getAllMaterialList, queryOther, save, saveQg, saveQy, removeById, removeByIdQg, removeByIdQy, getMyList, getMyListQg, getMyListQy, getXzqh } from "@/api/modules/release";
import { uploadFile } from '@/api/modules/upload'
import { getUserInfo } from '@/api/modules'

export default {
  data () {
    return {
      activeName: 'first',
      gyparams: {
        id: undefined,
        page: 1,
        pageSize: 10,
      },
      gyTotal: 0,
      gyTableData: [],

      qgParams: {
        id: undefined,
        page: 1,
        pageSize: 10,
      },
      qgTotal: 0,
      qgTableData: [],

      qyParams: {
        id: undefined,
        page: 1,
        pageSize: 10,
      },
      qyTotal: 0,
      qyTableData: [],

      title: '',
      queryParams: {
        page: 1,
        limit: 10,
        materialname: ''
      },
      radio: '',
      total: 0,
      form: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
        company: undefined,
        companyAddress: undefined,
        name: undefined,  //药材名称
        spec: undefined, //苦
        stockcount: undefined, //库存量
        buycount: undefined, //采购数量
        addr: undefined, //产地
        drugType: undefined, //药品分类
        stockaddr: undefined, //货源所在地
        deliveryaddr: undefined,// 库存地
        picsurl: '',//产品图
        pics: '',//产品图
        zjReportPics: '', //质检图
        descriptions: undefined,// 描述
        contacter: undefined, //联系人
        contacttel: undefined, //联系电话
        deadline: undefined,// 截止日期
        price: undefined, //单价
        unit: undefined, //单位
        standard: undefined, //质量标准
        idcadeno: undefined, // 详细地址
        mid: undefined,
        publisher: undefined, //发布人
        publishername: undefined,
        publishdatetime: undefined, //发布时间
        free: undefined, //是否付费
        fee: undefined, //费用
        priceUnit: '元'// 价格的单位
      },
      nameOptions: [], // 药材单
      otherList: [], // 通过药材获取其他
      otherForm: {},
      mcateData: [], // 分类
      materialname: '',
      mid: undefined,
      gridVisible: false,
      rules: {
        name: [
          { required: true, message: "请输入药材", trigger: ["blur", "change"] },
        ],
        stockcount: [
          { required: true, message: "请输入库存量", trigger: ["blur", "change"] },
        ],
        buycount: [
          { required: true, message: "请输入采购数量", trigger: ["blur", "change"] },
        ],
        price: [
          { required: true, message: "请输入价格", trigger: ["blur", "change"] },
        ],
        contacttel: [
          { required: true, message: "请输入联系电话", trigger: ["blur", "change"] },
        ],
        deadline: [
          { required: true, message: "请选择截止日期", trigger: ["blur", "change"] },
        ],
        free: [
          { required: true, message: "请选择是否付费", trigger: ["blur", "change"] },
        ]
      },
      dialogFormVisible: false,
      priceUnitList: [
        {name: '元'},
        {name: '万元'},
      ],
      provinceList: [],
      cityList: [],
      areaList: [],
    }
  },
  created () {
    this.getAllMcate()
    this.getList()
    this.getXzqh()
  },
  methods: {
    // 2 省 3 市 4 区
    getXzqh(){
      let params = {
        level: 2
      };
      getXzqh(params).then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data;
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    // 选择省份调取城市数据
    changeProvince(val) {
      let obj = this.provinceList.find(item=>{
        return item.xzqhdm == val
      })
      this.form.provinceName = obj.xzqhmc
      this.form.city = ''
      this.form.area = ''
      this.cityList = []
      this.areaList = [];
      let params = {
        parentCode: val,
        level: 3
      };
      getXzqh(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data;
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    // 选择城市调取区域数据
    changeCity(val) {
      let obj = this.cityList.find(item=>{
        return item.xzqhdm == val
      })
      this.form.cityName = obj.xzqhmc
      this.form.area = ''
      this.areaList = []
      let params = {
        parentCode: val,
        level: 4
      };
      getXzqh(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data;
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    changeArea(val) {
      let obj = this.areaList.find(item=>{
        return item.xzqhdm == val
      })
      this.form.areaName = obj.xzqhmc
      this.form.area = val
    },
    handleClick () {
      if (this.activeName == 'first') {
        this.getList()
      } else if (this.activeName == 'second') {
        this.getSeek()
      } else {
        this.getQyList()
      }
    },
    getAllMcate () {
      getAllMcate().then(res => {
        this.mcateData = res.data
      })
    },
    getAllMaterialList () {
      getAllMaterialList(this.queryParams).then(res => {
        this.nameOptions = res.data.content

        this.nameOptions.forEach((item, index) => {
          item.index = (this.queryParams.page - 1) * this.queryParams.limit + index + 1;
        })
        this.total = res.data.totalElements
      })
    },
    getList () {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.gyparams.id = res.data.id
          this.form.publisher = res.data.id
          this.form.publishername = res.data.personalphone
          getMyList(this.gyparams).then(res => {
            if (res.code == 200) {
              this.gyTableData = res.data.data
              this.gyTotal = res.data.total
            }
          })
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    getSeek () {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.qgParams.id = res.data.id
          let params = {
            ...this.qgParams,
            type: 1
          }
          getMyListQg(params).then(res => {
            if (res.code == 200) {
              this.qgTableData = res.data.data
              this.qgTotal = res.data.total
            }
          })
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    getQyList () {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.qgParams.id = res.data.id
          let params = {
            ...this.qgParams,
            type: 2
          }
          getMyListQg(params).then(res => {
            if (res.code == 200) {
              this.qyTableData = res.data.data
              this.qyTotal = res.data.total
              // this.qyTableData = res.data.content
              // this.qyTableData.forEach(e => {
              //   if (e.createDate) {
              //     e.createDate = this.dateFormat(e.createDate)
              //   }
              //   if (e.deadline) {
              //     e.deadline = this.dateFormat(e.deadline)
              //   }
              // });
              // this.qyTotal = res.data.numberOfElements
            }
          })
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    dateFormat (dateData) {
      if (dateData) {
        var date = new Date(dateData);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var s = date.getHours();
        s = s < 10 ? "0" + s : s;
        var f = date.getMinutes();
        f = f < 10 ? "0" + f : f;
        var z = date.getSeconds();
        z = z < 10 ? "0" + z : z;
        const time = y + "-" + m + "-" + d;
        return time;
      }
      return;
    },
    gyAdd () {
      this.title = "供应新增"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },

    qgAdd () {
      this.title = "求购新增"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    qyAdd () {
      this.title = "企业求购"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },

    getTable () {
      this.gridVisible = true
      this.$nextTick(() => {
        this.getAllMaterialList()
      })
    },

    gySizeChange () {
      this.gyparams.pageSize = val
      this.gyparams.page = 1
      this.getList()
    },
    gyCurrentChange (val) {
      this.gyparams.page = val
      this.getList()
    },
    qgSizeChange () {
      this.qgparams.pageSize = val
      this.qgparams.page = 1
      this.getSeek()
    },
    qgCurrentChange (val) {
      this.qgparams.page = val
      this.getSeek()
    },

    qySizeChange () {
      this.qyParams.pageSize = val
      this.qyParams.page = 1
      this.getQyList()
    },
    qyCurrentChange (val) {
      this.qyParams.page = val
      this.getQyList()
    },

    handleSizeChange (val) {
      this.queryParams.limit = val
      this.queryParams.page = 1
      this.getAllMaterialList()
    },
    handleCurrentChange (val) {
      this.queryParams.page = val
      this.getAllMaterialList()
    },
    handleQuery () {
      this.getAllMaterialList()
    },
    resetQuery () {
      this.queryParams.materialname = ''
    },
    //  getSelected(row) {
    //     this.$refs.singleTable.setCurrentRow(row);
    //   },
    getSelected (val) {
      this.currentRow = val;
    },
    handleRowChange () {

    },
    rowClick (row) {
      this.radio = row.index
      this.otherForm = row
      queryOther({ mid: row.id }).then(res => {
        this.otherList = res.data
      })
    },
    handleDel (row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.activeName == 'first') {
          removeById(row.id).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.getList()
            } else {
              this.$notify({
                title: '信息',
                message: '请先登录以获取更多信息',
                type: 'info',
                offset: 100,
                duration: 2600
              });
            }
          })
        } else if (this.activeName == 'second') {
          removeByIdQg(row.id).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.getSeek()
            } else {
              this.$notify({
                title: '信息',
                message: '请先登录以获取更多信息',
                type: 'info',
                offset: 100,
                duration: 2600
              });
            }
          })
        } else {
          removeByIdQy(row.id).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.getQyList()
            } else {
              this.$notify({
                title: '信息',
                message: '请先登录以获取更多信息',
                type: 'info',
                offset: 100,
                duration: 2600
              });
            }
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleAddName () {
      this.$nextTick(() => {
        this.form.name = this.otherForm.materialname
        this.form.addr = this.otherForm.address
        this.form.spec = this.otherForm.spec
        this.form.standard = this.otherForm.standards
        this.form.unit = this.otherList.unit[0].name //单位
        this.form.drugType = this.mcateData[0].name //分类
        this.form.mid = this.otherForm.id
        this.gridVisible = false
      })
    },
    closeGrid () {
      this.materialname = ''
      this.gridVisible = false
    },
    // 上传图片限制
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 上传身份证
    handleIdcardFileUpload (fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      // this.upload(fd)
      uploadFile(fd)
        .then((res) => {
          if (this.form.picsurl === '') {
            this.form.picsurl = res.path
          } else {
            this.form.picsurl += ',' + res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => { })
    },
    handleIdcardFileUpload2 (fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      // this.upload(fd)
      uploadFile(fd)
        .then((res) => {
          if (this.form.zjReportPics === '') {
            this.form.zjReportPics = res.path
          } else {
            this.form.zjReportPics += ',' + res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => { })
    },
    handleIdcardFileUploadQg (fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      // this.upload(fd)
      uploadFile(fd)
        .then((res) => {
          if (this.form.pics === '') {
            this.form.pics = res.path
          } else {
            this.form.pics += ',' + res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => { })
    },
    handleIdcardFileUploadQy (fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      // this.upload(fd)
      uploadFile(fd)
        .then((res) => {
          if (this.form.pics === '') {
            this.form.pics = res.path
          } else {
            this.form.pics += ',' + res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => { })
    },
    closeDialog () {
      this.form = {
        company: '',
        companyAddress: '',
        name: '',  //药材名称
        spec: '', //苦
        stockcount: '', //库存量
        addr: '', //产地
        drugType: '', //药品分类
        stockaddr: '', //存货地
        picsurl: '',//产品图
        zjReportPics: '', //质检图
        descriptions: '',// 描述
        contacter: '', //联系人
        contacttel: '', //联系电话
        deadline: '',// 截止日期
        price: '', //单价
        unit: '', //单位
        standard: '', //质量标准
        idcadeno: '', // 详细地址
        priceUnit: '元',// 价格的单位
        free: '', //是否付费
        fee: '', //费用
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      }
      // this.provinceList = []
      this.cityList = []
      this.areaList = []
      // 关闭弹框清除上传图片
      if(this.activeName == 'first') {
        this.$refs.upload1.clearFiles()
        this.$refs.upload3.clearFiles()
      } else if(this.activeName == 'second') {
        this.$refs.upload2.clearFiles()
      } else if(this.activeName == 'third') {
        this.$refs.upload2.clearFiles()
      }
      this.dialogFormVisible = false
    },
    submitForm () {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          this.form.provinceName = this.form.provinceName? this.form.provinceName : ''
          this.form.cityName = this.form.cityName? this.form.cityName == this.form.provinceName ? '':this.form.cityName : ''
          this.form.areaName = this.form.areaName? this.form.areaName : ''
          if (this.activeName == 'first') {
            this.form.publisher = this.gyparams.id
            let params = {
              ...this.form,
              stockaddr: this.form.provinceName +this.form.cityName+this.form.areaName
            }
            save(params).then(res => {
              if (res.code == 200) {
                this.getList()
                this.dialogFormVisible = false
              } else {
                this.$notify({
                  title: '信息',
                  message: '请先登录以获取更多信息',
                  type: 'info',
                  offset: 100,
                  duration: 2600
                });
              }
            })
          } else if (this.activeName == 'second') {
            this.form.publisher = this.gyparams.id
            let params = {
              ...this.form,
              type: 1,
              deliveryaddr: this.form.provinceName +this.form.cityName+this.form.areaName
            }
           saveQg(params).then(res => {
              if (res.code == 200) {
                this.getSeek()
                this.dialogFormVisible = false
              } else {
                this.$notify({
                  title: '信息',
                  message: '请先登录以获取更多信息',
                  type: 'info',
                  offset: 100,
                  duration: 2600
                });
              }
            })
          } else {
            this.form.publisher = this.gyparams.id
            let params = {
              ...this.form,
              type: 2,
              deliveryaddr: this.form.provinceName +this.form.cityName+this.form.areaName
            }
            saveQg(params).then(res => {
              if (res.code == 200) {
                this.getQyList()
                this.dialogFormVisible = false
              } else {
                this.$notify({
                  title: '信息',
                  message: '请先登录以获取更多信息',
                  type: 'info',
                  offset: 100,
                  duration: 2600
                });
              }
            })
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.release {
  max-width: 1190px;
  margin: 20px auto 0;
  padding-bottom: 40px;
}
.mb10 {
  margin-bottom: 10px;
}
.df {
  display: flex;
}
.form-card {
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
/deep/ .el-dialog {
  min-width: 720px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 30%;
}
</style>