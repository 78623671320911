<template>
  <section class="GongsiYewu">
    <div class="w">
      <WaterTitle Chinese="公司业务" English="CORPORATE BUSINESS"></WaterTitle>
      <ul>
        <li v-for="item in busList" :key="item.id">
          <img :src="item.imgUrl" />
          <h3>{{ item.title }}</h3>
          <p>{{ item.description.slice(0, 62) }}...</p>
        </li>
      </ul>
      <img class="bg-deng" src="@/assets/imgs/deng.png" />
      <a class="look-more" @click="toBusiness">查看更多</a>
    </div>
  </section>
</template>

<script>
import WaterTitle from '@/components/WaterTitle.vue'

export default {
  mixins: [],
  components: {
    WaterTitle
  },
  data() {
    return {
      busList: []
    }
  },
  created() {
    this.getMsg()
  },
  methods: {
    toBusiness() {
      this.$router.push('/businessSector')
    },
    async getMsg() {
      const res = await this.$axios.getCorporateBusinessPage({
        offset: 1,
        limit: 7,
        statusCd: 0
      })
      this.busList = res.records
    }
  }
}
</script>

<style lang="scss" scoped>
section.GongsiYewu {
  padding: 100px 0;
  .w {
    position: relative;
    .bg-deng {
      position: absolute;
      right: 0;
      bottom: 50px;
    }
    .look-more {
      position: absolute;
      right: 100px;
      bottom: 50px;
      background-color: rgb(15, 77, 181);
      color: #fff;
      width: 150px;
      height: 45px;
      border-radius: 6px;
      letter-spacing: 2px;
      font-weight: 500;
      text-align: center;
      line-height: 45px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  ul {
    margin-top: 50px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    h3 {
      font-size: 20px;
      height: 40px;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 30px;
      color: #333;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 32px;
        height: 4px;
        background-color: #0054e4;
      }
    }
    p {
      color: #666;
      line-height: 1.5;
      margin-right: 40px;
      font-size: 16px;
    }
  }
}
</style>
