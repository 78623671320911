import {Message} from 'element-ui';

/**
 * 手机验证
 */
export function checkPhone(value) {
    if (value === '') {
        showWarningMsg("请输入手机号码")
        return false
    }
    let reg = /^1\d{10}$/
    if (reg.test(value)) {
        return true;
    }
    showWarningMsg("手机号码格式不正确")
    return false
}

/**
 * 密码验证
 */
export function checkPwd(value) {

    // var regexp = new RegExp('^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,20}$');
    // if (!regexp.test(value)) {
    //     showWarningMsg('密码由长度大于等于8位')
    //     return false;
    // }
   if (value.length < 8) {
       showWarningMsg('密码长度小于8!')
       return false;
        // callback(new Error('密码长度小于8!'))
    }

    return true
}

/**
 * 验证码验证
 */
export function checkCode(value) {
    if (value.length == 4) {
        return true;
    }
    showWarningMsg('验证码必须是4位数字')
    return false
}

/**
 * 公司名称必填
 */
export function checkContactName(value) {
    if (value.length == 0) {
        showWarningMsg('姓名必填')
        return false
    }
    return true;
}


/**
 * 验证码验证
 */
export function checkMerchantName(value) {
    if (value.length == 0) {
        showWarningMsg('公司名称必填')
        return false
    }
    return true;
}


/**
 * 身份证验证
 */
export function checkIdCard(value) {
    let reg = /^\d{15}|\d{18}$/
    if (reg.test(value)) {
        return true;
    }
    showWarningMsg('身份证必须是15或18位数字')
    return false
}

/**
 * 银行卡验证
 */
export function checkBankCard(value) {
    let reg = /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{18})$/
    if (reg.test(value)) {
        return true;
    }
    showWarningMsg('请输入正确的银行卡号')
    return false
}

function showWarningMsg(message) {
    Message({
        message: message, // error.message,
        type: 'warning',
        // duration: 5 * 1000,
    })
}

//正确返回true，错误则是false
export function checkTaxNo(code) {
    if (code.length == 0) {
        showWarningMsg('公司税号必填')
        return false
    }
    if(code.length != 18 && code.length != 15){
        showWarningMsg('请填写18位或15位号码')
        return false;
    }
    //十八位新码
    if(code.length == 18){
        var regex = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-90-9ABCDEFGHJKLMNPQRTUWXY])$/;
        if (!regex.test(code)) {
            return false;
        }
        var str = "0123456789ABCDEFGHJKLMNPQRTUWXY";
        var ws =[1,3,9,27,19,26,16,17,20,29,25,13,8,24,10,30,28];
        var codes = new Array();
        codes[0] = code.substr(0, code.length-1);
        codes[1] = code.substr(code.length - 1, code.length);
        var  sum = 0;
        for (var  i = 0; i < 17; i++) {
            sum += str.indexOf(codes[0].charAt(i)) * ws[i];
        }
        var  c18 = 31 - (sum % 31);
        if (c18 == 31) {
            c18 = 'Y';
        } else if (c18 == 30) {
            c18 = '0';
        }
        if (str.charAt(c18) != codes[1].charAt(0)) {
            showWarningMsg('号码不正确')
            return false;
        }
        return true;

    }
    //十五位编码
    else{
        var ret=false;
        var sum=0;
        var s=[];
        var p=[];
        var a=[];
        var m=10;
        p[0]=m;
        for(var i=0;i<code.length;i++){
            a[i]=parseInt(code.substring(i,i+1),m);
            s[i]=(p[i]%(m+1))+a[i];
            if(0==s[i]%m){
                p[i+1]=10*2;
            }else{
                p[i+1]=(s[i]%m)*2;
            }
        }
        if(1==(s[14]%m)){
            ret=true;
        }else{
            ret=false;
        }
        return ret;
    }

}
