import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'reset-css'
import ElementUI from 'element-ui';
// import VueHtml5Editor from 'vue-html5-editor'
import 'element-ui/lib/theme-chalk/index.css';
import './lib/filter' // global filter
import api from '@/api'
Vue.prototype.$axios = api
Vue.use(ElementUI);
// Vue.use(VueHtml5Editor)
Vue.config.productionTip = false

import {recover} from '@/utils/index'

Vue.prototype.recover = recover 

Vue.prototype.openLoading = function () {
  const loading = this.$loading({
    // 声明一个loading对象
    fullscreen: true,
    lock: true, // 是否锁屏
    text: '加载中', // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(190,189,189,0.8)', // 背景颜色
    // target: '.el-table, .table-flex, .region',       // **需要遮罩的区域，这里写要添加loading的选择器**
    // fullscreen: false,
    customClass: 'loadingclass', // **遮罩层新增类名，如果需要修改loading的样式**
  })
  setTimeout(function () {
    // 设定定时器，超时3S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close() // 关闭遮罩层
  }, 3000)
  return loading
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
