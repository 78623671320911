<template>
  <section class="FuwuJieshao">
    <WaterTitle Chinese="服务介绍" English="SERVICE INTRODUCTION"></WaterTitle>
    <div class="w">
      <ul class="tabs">
        <li @click="goToService(1)">
          <img src="@/assets/imgs/tab1.png">
          <h3>花茶企业</h3>
          <p>SCENTED TEA</p>
        </li>
        <li @click="goToService(2)">
          <img src="@/assets/imgs/tab2.png">
          <h3>金融服务</h3>
          <p>
            FINANCE</p>
        </li>
        <li @click="goToService(3)">
          <img src="@/assets/imgs/tab3.png">
          <h3>传媒设计</h3>
          <p>MEDIA</p>
        </li>
        <li @click="goToService(4)">
          <img src="@/assets/imgs/tab4.png">
          <h3>包装包材</h3>
          <p>PACKAGE</p>
        </li>
        <li @click="goToService(5)">
          <img src="@/assets/imgs/tab5.png">
          <h3>快运物流</h3>
          <p>LOGISTICS</p>
        </li>
        <li @click="goToService(6)">
          <img src="@/assets/imgs/tab6.png">
          <h3>机械设备</h3>
          <p>MACHINERY</p>
        </li>
      </ul>
      <div class="cards">
        <div class="card ZhaocaiToubiao">
          <div class="card-hd">
            <h4>招采投标</h4>
            <a @click="toServiceTender(0)">
              <span>查看更多</span>
              <img src="@/assets/imgs/right.png">
            </a>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="card-bd">
            <table>
              <thead>
                <tr>
                  <th style="width: 200px">企业名称</th>
                  <th style="width: 200px">品种</th>
                  <th style="white-space: nowrap;width: 100px">招标状态</th>
                  <th style="white-space: nowrap;width: 150px">报价剩余时间</th>
                  <!-- <th style="width: 100px">操作</th> -->
                </tr>
              </thead>
            </table>
            <vueSeamlessScroll :data="inquiryList" class="seamless-warp">
              <table>
                <tbody>
                  <tr v-for="(item,index) in inquiryList" :key="index">
                    <td style="width: 200px">{{ item.cname }}</td>
                    <td style="width: 200px">{{ item.mname }}</td>
                    <td style="white-space: nowrap;width: 100px">{{ item.bidStatus | BID_STATUS }}
                    </td>
                    <td style="width: 150px">
                      <reduceTime :refs="`reduceTime${index}`" :deadlineDate="item.deadlineDate" />
                    </td>
                    <!-- <td style="width: 100px">
                      <div class="link">报价</div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </vueSeamlessScroll>
          </div>
        </div>
        <div class="card ZuixinZhongbiao">
          <div class="card-hd">
            <h4>最新中标状态</h4>
            <a @click="toServiceTender(1)">
              <span>查看更多</span>
              <img src="@/assets/imgs/right.png">
            </a>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="zuixin-bd news">
            <ul>
              <vueSeamlessScroll :data="inquiryUserList" class="seamless-warp">
                <li v-for="(item,index) in inquiryUserList" :key="index">
                  <div style="width: 50%;">
                    <h5>{{item.cname}}</h5>
                    <p>{{item.contacttel}}</p>
                  </div>
                  <div style="width: 20%;">
                    <h5>中标</h5>
                  </div>
                  <div style="width: 30%;">
                    <h5>{{item.mname}}</h5>
                    <p>{{item.zbDate}}</p>
                  </div>
                </li>
              </vueSeamlessScroll>
            </ul>
          </div>
        </div>
        <div class="card JiageZhishu">
          <div class="card-hd">
            <h4>价格指数</h4>
            <a @click="toServicePrice()">
              <span>查看更多</span>
              <img src="@/assets/imgs/right.png">
            </a>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="card-bd">
            <table>
              <thead>
                <tr>
                  <th style="width: 110px">品名</th>
                  <th style="width: 110px">规格</th>
                  <th style="width: 110px">产地</th>
                  <th style="width: 110px">价格</th>
                  <th style="width: 110px">市场</th>
                  <!-- <th>日涨幅</th> -->
                </tr>
              </thead>
            </table>
            <vueSeamlessScroll :data="materialList" class="seamless-warp">
              <table>
                <tbody>
                  <tr v-for="(item,index) in materialList" :key="index">
                    <td style="width: 110px">{{ item.mname }}</td>
                    <td style="width: 110px">{{ item.mspec }}</td>
                    <td style="width: 110px">{{item.maddr}}</td>
                    <td style="width: 110px">{{item.price}}</td>
                    <td style="width: 110px">{{item.market}}</td>
                    <!-- <td>
                    <span class="strong">0.00%</span>
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </vueSeamlessScroll>
          </div>
        </div>
        <div class="card GongyingXinxi">
          <div class="card-hd">
            <div class="flex">
              <h4>供应信息</h4>
              <h4 class="buy" @click="toSupply(1)">求购信息</h4>
            </div>

            <a @click="toSupply(0)">
              <span>查看更多</span>
              <img src="@/assets/imgs/right.png">
            </a>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="card-bd">
            <table>
              <thead>
                <tr>
                  <th>品名</th>
                  <th>规格</th>
                  <th>产地</th>
                  <th>价格</th>
                  <th>数量</th>
                  <th>电话</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in yshSupplyList" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.spec }}</td>
                  <td>{{item.addr}}</td>
                  <td>{{item.price}}</td>
                  <td>{{item.buycount}}</td>
                  <td>
                    <span class="strong">{{item.contacttel}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a class="look-more">查看详情&gt;&gt;</a>
    </div>

  </section>
</template>

<script>
import WaterTitle from '@/components/WaterTitle.vue'
import reduceTime from '@/components/reduceTime/index.vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getPleaseBuy } from '@/api/modules/service.js'

export default {
  mixins: [],
  components: { WaterTitle, reduceTime, vueSeamlessScroll },
  data() {
    return {
      searchInquiryParams: {
        cname: '',
        type: 0,
        qstatus: 2,
        payment: 2,
        pageIndex: 1,
        pageSize: 20
      },
      materialParams: {
        isChange: 0,
        isCheck: 0,
        market: '',
        mcate: '',
        mname: '',
        order: '',
        page: 1,
        pageSize: 20,
        sort: ''
      },
      yshSupplyParams: {
        page: 1,
        pageSize: 10
      },
      inquiryList: [],
      inquiryUserList: [],
      materialList: [],
      yshSupplyList: []
    }
  },
  created() {
    this.$axios.getYshInquiryInquiryList(this.searchInquiryParams).then((res) => {
      // console.log('getAllInquiryList:',res);
      this.inquiryList = res.data.content || []
      setTimeout(() => {
        this.inquiryList.forEach((iItem, index) => {
          // console.log("inquiryList:",this.$refs[`reduceTime${index}`]);
          this.$refs[`reduceTime${index}`] && this.$refs[`reduceTime${index}`].countTime()
        })
      }, 1000)
      // this.$nextTick(() => {
      //   this.inquiryList.forEach( (iItem,index) => {
      //     console.log("inquiryList:",this.$refs[`reduceTime${index}`]);
      //     this.$refs[`reduceTime${index}`] && this.$refs[`reduceTime${index}`].countTime()
      //   })
      // })
    })
    this.$axios.getInquiryUser(20).then((res) => {
      this.inquiryUserList = res.data || []
    })
    this.$axios.getYscPriceAllMaterial(this.materialParams).then((res) => {
      // console.log('getAllInquiryList:',res);
      this.materialList = res.data || []
    })
    // 求购信息
    const data = new FormData()
    data.append('page', this.yshSupplyParams.page)
    data.append('pageSize', this.yshSupplyParams.pageSize)
    getPleaseBuy(data).then((res) => {
      // console.log('getAllInquiryList:',res);
      this.yshSupplyList = res.data.data || []
    })
  },
  methods: {
    goToService(activeTab){
      this.$router.push({path:'/serviceHall', query: {activeTab}})
    },
    toServiceTender(activeTab) {
      this.$router.push({ name: 'ServiceTender', query: { activeTab } })
    },
    toServicePrice() {
      this.$router.push({ name: 'ServicePrice' })
    },
    toSupply(activeTab) {
      this.$router.push('/supplyDemand?activeTab=' + activeTab)
    }
  }
}
</script>

<style lang='scss' scoped>
.flex {
  display: flex;
}
.seamless-warp {
  overflow: hidden;
  height: 320px;
}
section.FuwuJieshao {
  padding: 50px 0;
  background-color: rgb(246, 250, 253);
  .w {
    position: relative;
    padding-bottom: 50px;
    .look-more {
      color: #0e4db5;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .tabs {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    gap: 5px;
    li {
      background-color: #fff;
      padding: 20px;
      cursor: pointer;
      h3 {
        font-size: 20px;
        font-weight: 700;
        color: #333;
        margin: 30px 0 10px;
      }
      p {
        color: #aaa;
      }
    }
  }
  .cards {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
    gap: 20px;
    h4 {
      font-size: 18px;
      font-weight: 700;
      &.buy {
        color: #0e4db6;
        margin-left: 15px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .card {
      background-color: #fff;
      .card-hd {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        a {
          cursor: pointer;
        }
        span {
          font-size: 12px;
          color: #0147eb;
          margin-right: 5px;
        }
        img {
          vertical-align: middle;
        }
      }
      .hr {
        width: 100%;
        height: 4px;
      }
      .card-bd {
        padding: 15px;
      }
    }
    .ZhaocaiToubiao {
      grid-column: 1 / 3;
    }
    .GongyingXinxi {
      grid-column: 2 / 4;
    }
  }
  table {
    border-top: 1px solid #b2c1da;
    width: 100%;
    th,
    td {
      padding: 8px 15px;
      text-align: left;
      font-size: 13px;
      color: #555;
    }
    thead {
      background-color: #def2ff;
      font-weight: 700;
      font-size: 14px;
      th {
        color: #333;
      }
    }
    tbody {
      tr:nth-child(2n) {
        background-color: #f2faff;
      }
    }
    .link {
      border: 1px solid #0147eb;
      color: #0147eb;
      padding: 2px 15px;
      display: inline-block;
      font-size: 12px;
      background-color: rgb(242, 248, 254);
      white-space: nowrap;
    }
  }
  .zuixin-bd {
    padding: 0 15px;
    max-height: 320px;
    overflow: auto;
    li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2;
      padding: 0 4px;
      &:hover {
        background-color: rgb(240, 251, 254);
      }

      h5 {
        font-weight: 700;
        font-size: 14px;
        color: #555;
        margin-top: 12px;
      }
      p {
        color: #aaa;
        font-size: 13px;
        padding: 12px 0;
      }
    }
  }
}
.news::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

/* 设置滚动条滑块的样式 */
.news::-webkit-scrollbar-thumb {
  background-color: #dbdfe9;
  border-radius: 2px;
}

/* 设置滚动条滑块悬停时的样式 */
.news::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* 设置滚动条轨道的样式 */
.news::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
