<template>
  <div class="my-notice">
    <el-form label-width="80px" size="mini" style="width: 100%">
      <el-form-item label="公告">
        <el-input type="textarea" :rows="4" v-model="description"></el-input>
      </el-form-item>

      <el-form-item size="large">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateDescription } from "@/api/modules/notice";
export default {
  data () {
    return {
      description: '',
    }
  },


  computed: {

  },
  mounted () {
  },
  created () {
    this.$nextTick(() => {

      console.log(this.$store.state.userInfo.description, 3223)
      this.description = this.$store.state.userInfo.description
    })
  },
  methods: {

    onSubmit () {
      if (!this.$store.state.userInfo.id) {
        this.$message.error('请先登录')
      } else {
        updateDescription({ id: this.$store.state.userInfo.id, description: this.description }).then(res => {
          this.$message.success('修改成功')
        })
      }


    },
  }
}
</script>

<style lang="less" scoped>
.my-notice {
  max-width: 1190px;
  margin: 50px auto 0;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
