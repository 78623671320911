import fetch from '../../utils/fetch'

// 获取用户信息
export function getUserInfo() {
    return fetch({
        url: '/api/mall/yshUser/getUserInfo',
        method: 'POST',
        data: '',
    })
}
// 澄清公告
export function logOff(data) {
    return fetch({
        url: '/api/user/logOff',
        method: 'POST',
        data,
    })
}

export function getDictInfo(data) {
    return fetch({
        url: '/api/indexconfig/getDictInfo?type=' + data,
        method: 'GET',
    })
}
export function smsLogin(data) {
    return fetch({
        url: '/api/user/login',
        method: 'POST',
        data,
    })
}
// 登录
export function loginPwd(data) {
    return fetch({
        url: '/api/user/loginPwd',
        method: 'POST',
        data,
    })
}
// 获取验证码
export function getSmsCode(data) {
    return fetch({
        url: '/api/sms/getSmsCode',
        method: 'POST',
        data,
    })
}
// 注册
export function register(data) {
    return fetch({
        url: '/api/user/register',
        method: 'POST',
        data,
    })
}

export function getInfo() {
    return fetch({
        url: '/api/ysc/yscCompanyJoin/getInfo',
        method: 'POST',
        data: '',
    })
}
export function getAllTypeList() {
    return fetch({
        url: '/api/ysc/yscCompany/getAllTypeList',
        method: 'POST',
        data: '',
    })
}
export function doCompanyJoinApply(data) {
    return fetch({
        url: '/api/ysc/yscCompanyJoin/doCompanyJoinApplyPc',
        method: 'POST',
        data,
    })
}
// 资质认证
export function updateUser(data) {
    return fetch({
        url: '/api/user/updateUser',
        method: 'POST',
        data,
    })
}
// 个人资质认证
export function updatePerson(data) {
    return fetch({
        url: '/api/user/personAttestation',
        method: 'POST',
        data,
    })
}
// 合同列表
export function getAgreeList(data) {
    return fetch({
        url: '/api/mall/yshAgreement/list',
        method: 'POST',
        data,
    })
}




//  浏览量
export function saveView(data) {
    return fetch({
        url: '/api/pageView/save',
        method: 'POST',
        data:data
    })
}