<template>
  <div>
    <!-- 顶部区域 -->
    <HomeHeader></HomeHeader>
    <slot></slot>
    <!-- 底部区域 -->
    <HomeFooter></HomeFooter>
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader.vue'
import HomeFooter from '@/components/HomeFooter.vue'

export default {
  mixins: [],
  components: {
    HomeHeader,
    HomeFooter
  },
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang='scss' scoped>

</style>
