<template>
  <section class="banner">
    <el-carousel class="ul" :height="bannerHeight">
      <el-carousel-item v-for="item in banners" class="li" :key="item.id">
        <img :src="item.imgUrl" />
        <!-- <div class="w">
          <div class="words">
            <h1>{{item.title}}</h1>
            <p>
              <span>{{item.description.slice(0,24)}}</span>
            </p>
            <p v-if="item.description.length > 24">
              <span>{{item.description.slice(24)}}</span>
            </p>
          </div>
        </div> -->
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      bannerHeight: '',
      screenWidth: document.body.clientWidth,
      banners: []
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.initBannerHeight()
          that.timer = false
        }, 400)
      }
    }
  },
  created() {
    this.getMsg()
    this.initBannerHeight()
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  methods: {
    initBannerHeight() {
      const clientWidth = document.body.clientWidth
      if (clientWidth > 1600) {
        this.bannerHeight = '630px'
      } else if (clientWidth > 1500) {
        this.bannerHeight = '580px'
      } else if (clientWidth > 1400) {
        this.bannerHeight = '530px'
      } else {
        this.bannerHeight = '480px'
      }
    },
    async getMsg() {
      const res = await this.$axios.getBannerPage({
        sort: 0,
        offset: 1,
        limit: 2,
        statusCd: 0
      })
      this.banners = res.records
    }
  }
}
</script>

<style lang='scss' scoped>
section.banner {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .li .w {
    position: relative;
    z-index: 2;
    height: 630px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h1 {
    font-size: 58px;
    margin-bottom: 20px;
  }
  .words {
    p {
      margin-bottom: 6px;
      span {
        display: inline-block;
        background-color: rgba(0, 84, 228, 0.6);
        color: #fff;
        padding: 5px;
        font-size: 18px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>

<style lang="scss">
.banner {
  .el-carousel__indicators {
    width: 1200px;
    margin: 0 auto;
    bottom: 100px;
  }
  .el-carousel__indicator {
    .el-carousel__button {
      width: 30px;
      height: 3px;
      opacity: 1;
    }
    &.is-active .el-carousel__button {
      background-color: #7fc179;
    }
  }
}
</style>
