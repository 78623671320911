/*
 * @Author: guojiren 7755@123.com
 * @Date: 2022-09-13 17:22:41
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-03-27 11:38:42
 * @FilePath: \xinghe-xinxi-pc\src\api\bid.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {get, post } from '@/utils/fetch'
import fetch from '@/utils/fetch'

export function getInqueryMaterials(data) {
    return get('/api/mall/yshInquiryM/getPcAllList', data)
}

export function userBidInfo(data) {
    return get('/api/mall/yshInquiryMU/userBidInfo?id=' + data)
}

// 获取保证金和资质
export function uploadSample(data) {
    return get('/api/mall/yshInquiryMU/getDeposit?id=' + data)
}

// 上传保证金和资质
export function uploadDeposit(data) {
    // return post('/api/mall/yshInquiryMU/uploadDeposit', data)
    return fetch({
        url: '/api/mall/yshInquiryMU/uploadDeposit',
        method: 'POST',
        data,
    })
}

// 获取保证金
export function getBzjData(id) {
    return get('/api/mall/yshInquiryMU/getDeposit?id=' + id)
}

export function getInqueryMaterialDetail(data) {
    return post('/api/mall/yshInquiryM/mdetail', data)
}

// 报价
export function addInqueryMU(data) {
    return post('/api/mall/yshInquiryMU/add', data)
}

export function getInqueryMU(data) {
    return post('/api/mall/yshInquiryMU/getMyInquiryAnswer', data)
}

export function getMyInqueryMaterials(data) {
    return post('/api/mall/yshInquiryMU/getMyAllInquiryList', data)
}

export function getZbPageInquiry(data) {
    return post('/api/mall/yshInquiryMU/getZbPageInquiry', data)
}

export function getMyInquery(data) {
    return post('/api/mall/yshInquiry/getPageInquiry', data)
}

export function getInquiryUser(limit) {
    return get('/api/mall/yshInquiryMU/getInquiryUser/' + limit)
}

export function getInqueryList(limit) {
    return get('/api/mall/yshInquiryM/getList/' + limit)
}
// 采购详情表头
export function getInqueryMaterialMain(data) {
    return post('/api/mall/yshInquiryM/main', data)
}
// 采购详情
export function getDetailData(data) {
    return post('/api/mall/yshInquiryM/detail', data)
}
// 采购列表
export function getInqueryMaterialsList(data) {
    return post('/api/mall/yshInquiry/getInquiryList', data)
}
export function getInqueryDetail(data) {
    return post('/api/mall/yshInquiry/detail?id=' + data)
}

// // 澄清公告
// export function getFicationList(data) {
//   return post('/api/mall/yshClarification/list' + data)
// }
// 澄清公告
export function getFicationList(data) {
    return fetch({
        url: '/api/mall/yshClarification/list',
        method: 'POST',
        data,
    })
}

// 公告详情
export function getDicDetail(data) {
    return fetch({
            url: '/api/mall/yshClarification/getById',
            method: 'POST',
            data,
        })
        // return post('/api/mall/yshClarification/getById?id=' + data)
}

// 批量招标
export function getBatchAdd(data) {
    return fetch({
        url: '/api/mall/yshInquiryMU/batchAdd',
        method: 'POST',
        data,
    })
}

export function getDetailPrice(id) {
    return post('/api/mall/yshInquiryMU/getDetails/' + id)
}
export function addZtbOrder(data) {
    return post('/api/mall/pay/addZtbOrder',data)
}
export function checkuserPaystatus(data) {
    return post('/api/mall/picpay/checkUserPayStatus',data)
}
export function doadd(data) {
    return post('/api/mall/picpay/doadd',data)
}
export function getUserPayPageList(data) {
    return post('/api/mall/picpay/getUserPayPageList',data)
}
export function getAccountInfo(data) {
    return post('/api/mall/picpay/getAccountInfo',data)
}