import { post, json, get } from '@/utils/fetch'
import fetch from '@/utils/fetch'

// 获取服务列表
export function getAllServiceList(data) {
  return get('/api/pc/ysc/yscCompany/getAllTypeList', data)
}
// 获取服务详情
export function getServiceDetail(id) {
  return get('/api/pc/ysc/yscCompany/getDetailById/' + id)
}
// 求购列表
export function getPleaseBuy(data) {
  return fetch.postForm('/api/mall/yshPurchase/getList', data, {
    'Content-Type': 'multipart/form-data'
  })
}
export function getTopTypeList(num) {
    // /pc/ysc/yscCompany/getTopTypeList
    return get('/api/pc/ysc/yscCompany/getTopTypeList/' + num);
}