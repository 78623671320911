import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}')
    },
    getters: {
        userInfo: function(state) {
            return state.userInfo
        }
    },
    mutations: {
        userInfo: (state, userInfo) => {
            state.userInfo = userInfo
        }
    },
    actions: {},
    modules: {}
})