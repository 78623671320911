import fetch,{get, post} from '../utils/fetch';

// 获取分类列表
export function getCategorys() {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getAllTypeList',
        method: 'GET',
    });
}

// 获取分类列表
export function getCompanyListByType(data) {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getAllTypeList',
        method: 'GET',
        data
    });
}

// 获取分类列表
export function getAllTypeListNoTop(data) {
    return post('/api/ysc/yscCompany/getAllTypeListNoTop', data);
}

export function getAllListByType(data) {
    return post('/api/ysc/yscCompany/getAllListByType', data);
}

export function getAllType() {
    return get('/api/ysc/yscCompany/getAllType');
}



// 获取分类列表
export function getSwipers() {
    return fetch({
        url: '/api/mall/swiper/getSwiperList/1',
        method: 'GET',
    });
}

// 获取分类列表
export function getHotService() {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getRecommondTop/4',
        method: 'GET',
    });
}

// 获取分类列表
export function getTopCategorys() {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getTopTypeList/4',
        method: 'GET',
    });
}

export function getCompany(id) {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getDetailById/' + id,
        method: 'GET',
    });
}
export function getProductList(params) {
    return fetch({
        url: '/api/ysc/yscCompanyProductions/getAllListByCompanyid',
        method: 'post',
        params
    });
}
export function getProductByid(data) {
    return post('/api/ysc/yscCompanyProductions/getDetailById', data);
}
// 产品列表
export function getPros(params) {
    return get('/api/ysc/yscCompanyProductions/getAllList', params);
}
export function getProDetail(data) {
    return post('/api/ysc/yscCompanyProductions/getDetailById', data);
}
export function delPro(data) {
    return post('/api/ysc/yscCompanyProductions/status/delete', data);
}

// 企业类型
export function getComTypes() {
    return fetch({
        url: '/api/indexconfig/getDictInfo?type=companytype',
        method: 'GET',
    });
}
export function editProduct(data) {
    return post('/api/ysc/yscCompanyProductions/save', data);
}

export function getCompanyByUser() {
    return get('/api/ysc/yscCompany/getCompanyByUser');
}
export function editCom(data) {
    return post('/api/ysc/yscCompany/save', data);
}
export function editDesc(data) {
    return post('/api/ysc/yscCompany/updateDecriptions', data);
}
export function getComDetail(params) {
    return fetch({
        url: '/api/ysc/yscCompany/getDetailById',
        method: 'post',
        params
    })
}
export function uploadOneImg() {
    return '/info-admin/upload/imageAndthumb'
}
export function uploadMultiImg() {
    return '/info-admin/upload/imageMultiAndthumb'
}
export function uploadZiZhi() {
    return '/info-admin/upload/image'
}
export function uploadAvi() {
    return '/info-admin/api/mall/upload'
}
export function getRecService() {
    return fetch({
        url: '/api/pc/ysc/yscCompany/getNewTop/10',
        method: 'GET',
    });
}