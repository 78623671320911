import {
    post,
    // json,
    get,
} from '../../utils/fetch';
import fetch from '../../utils/fetch'

// 获取所有药材
export function getAllMaterialList(data) {
    return get('/api/mall/yshMaterial/getAllMaterialList', data);
}

// 通过药材获取产地规格标准
export function queryOther(data) {
    return post('/api/mall/yshMaterial/queryOther', data);
}

//新增供应
export function save(data) {
    // return post('/api/mall/yshSupply/save', data);
    return fetch({
        url: '/api/mall/yshSupply/save',
        method: 'POST',
        data,
    })
}
//新增求购
export function saveQg(data) {
    // return post('/api/mall/yshSupply/save', data);
    return fetch({
        url: '/api/mall/yshPurchase/save',
        method: 'POST',
        data,
    })
}
export function saveQy(data) {
    // return post('/api/mall/yshSupply/save', data);
    return fetch({
        url: '/api/mall/yshPurchase/pc/savec',
        method: 'POST',
        data,
    })
}

//删除供应
export function removeById(id) {
    return fetch({
        url: '/api/mall/yshSupply/removeById?id=' + id,
        method: 'POST',
    })
}
//删除求购
export function removeByIdQg(id) {
    return fetch({
        url: '/api/mall/yshPurchase/removeById?id=' + id,
        method: 'POST',
    })
}


export function removeByIdQy(id) {
    return fetch({
        url: '/api/mall/yshPurchase/dodelete?ids=' + id,
        method: 'POST',
    })
}


// 供应list
export function getMyList(data) {
    return post('/api/mall/yshSupply/getMyList', data);
}

// 求购list
export function getMyListQg(data) {
    return post('/api/mall/yshPurchase/getMyList', data);
}
// 企业求购list
export function getMyListQy(data) {
    return get('/api/mall/yshPurchase/pc/listc', data);
}
// 查询省市区
export function getXzqh(data) {
    return post('/api/mall/yshPurchase/getXzqh', data);
}