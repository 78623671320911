<template>
  <div>
    <div class="banxin">
      <div class="tags" v-if="checkFlag == 0">{{ tipTxt }}</div>

      <div class="tabs" v-else-if="checkFlag == 1">{{ tipTxt }}</div>
      <div class="fags" v-else-if="checkFlag == 2">{{ tipTxt }}</div>
      <div class="tags" v-else>{{ tipTxt }}</div>
      <div class="form-style-login">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rulesLogin"
          label-width="150px"
          :disabled="disabled"
          v-if="type !== 4"
        >
          <!-- 上 -->
          <div class="top">
            <el-form-item label="入驻类别:" prop="gysType">
              <el-select
                v-model="ruleForm.gysType"
                placeholder="请先选择入驻类别"
                style="width: 100%"
                @change="changeSel"
              >
                <el-option
                  v-for="(item, index) in options1"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <!-- 下 -->
          <el-row :gutter="20">
            <!-- 左 -->
            <el-col :span="8">
              <el-form-item label="企业名称:" prop="companyName">
                <el-input v-model="ruleForm.companyName"></el-input>
              </el-form-item>

              <el-form-item label="营业执照代码:" prop="yyzzCode">
                <el-input v-model="ruleForm.yyzzCode"></el-input>
              </el-form-item>

              <el-form-item label="法人代表:" prop="legal">
                <el-input v-model="ruleForm.legal"></el-input>
              </el-form-item>

              <el-form-item label="组织机构代码证:" prop="orgCode">
                <el-input v-model="ruleForm.orgCode"></el-input>
              </el-form-item>
              <el-form-item label="电话号码:" prop="contactTel">
                <el-input v-model="ruleForm.contactTel"></el-input>
              </el-form-item>

              <el-form-item label="税务登记证:" prop="taxRegistPic">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload1"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.taxRegistPic"
                    :src="infoUrl + ruleForm.taxRegistPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="授权委托书:" prop="attorneyPic">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload9"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.attorneyPic"
                    :src="infoUrl + ruleForm.attorneyPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('5') != -1"
                label="特殊品种经营许可证:"
                prop="sptlPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload7"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.sptlPic"
                    :src="infoUrl + ruleForm.sptlPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('4') != -1"
                label="GSP证书:"
                prop="gspPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload6"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.gspPic"
                    :src="infoUrl + ruleForm.gspPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="type == 1"
                label="相关证书(多选):"
                prop="certificateTypeList"
              >
                <el-select
                  v-model="ruleForm.certificateTypeList"
                  placeholder="请选择相关证书"
                  multiple
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- 中 -->
            <el-col :span="8">
              <el-form-item label="发票:" prop="invoiceType">
                <el-select
                  v-model="ruleForm.invoiceType"
                  placeholder="请选择发票"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in options2"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="开票单位：" prop="fpdw">
                <el-input v-model="ruleForm.fpdw"></el-input>
              </el-form-item>
              <el-form-item label="单位地址：" prop="fpdwAddr">
                <el-input v-model="ruleForm.fpdwAddr"></el-input>
              </el-form-item>
              <el-form-item label="开票税号:" prop="fpdwSh">
                <el-input v-model="ruleForm.fpdwSh"></el-input>
              </el-form-item>
              <el-form-item label="委托人身份证:" prop="idcadeno">
                <el-input v-model="ruleForm.idcadeno"></el-input>
              </el-form-item>
              <el-form-item label="承诺书:" prop="commitmentPic">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload2"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.commitmentPic"
                    :src="infoUrl + ruleForm.commitmentPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="上传营业执照:" prop="yyzzPic">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.yyzzPic"
                    :src="infoUrl + ruleForm.yyzzPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('6') != -1"
                label="其他:"
                prop="otherPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload8"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.otherPic"
                    :src="infoUrl + ruleForm.otherPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('3') != -1"
                label="GMP证书:"
                prop="gmpPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload5"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.gmpPic"
                    :src="infoUrl + ruleForm.gmpPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>

            <!-- 右 -->
            <el-col :span="8">
              <el-form-item label="银行账号：" prop="bankCode">
                <el-input v-model="ruleForm.bankCode"></el-input>
              </el-form-item>
              <el-form-item label="开户行：" prop="bank">
                <el-input v-model="ruleForm.bank"></el-input>
              </el-form-item>
              <el-form-item label="授权委托人:" prop="leader">
                <el-input v-model="ruleForm.leader"></el-input>
              </el-form-item>
              <el-form-item label="授权委托人手机：" prop="leaderTel">
                <el-input v-model="ruleForm.leaderTel"></el-input>
              </el-form-item>
              <el-form-item> </el-form-item>
              <el-form-item> </el-form-item>
              <el-form-item> </el-form-item>
              <el-form-item label="委托人身份证照：" prop="idcadenoPics">
                <el-upload
                  name="sfzb"
                  class="upload-demo"
                  :before-upload="beforeAvatarUpload"
                  :show-file-list="false"
                  action="#"
                  :limit="2"
                  :http-request="handleIdcardFileUpload"
                >
                  <img
                    v-if="positive"
                    :src="infoUrl + positive"
                    class="avatar"
                  />

                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <p style="text-align: center; width: 150px">国徽面</p>
                  <!-- <span>人像面</span> -->
                  <!-- <el-button size="small" type="primary">点击上传</el-button>
                  <span
                    slot="tip"
                    class="el-upload__tip"
                    style="margin-left: 15px"
                    >大小2MB内的jpg/png文件(先上传国徽面)</span
                  > -->
                </el-upload>
                <el-upload
                  name="sfzz"
                  class="upload-demo"
                  :before-upload="beforeAvatarUpload"
                  :show-file-list="false"
                  action="#"
                  :limit="2"
                  :http-request="handleIdcardFileUpload"
                >
                
                  <img v-if="back" :src="infoUrl + back" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <p style="text-align: center; width: 150px">人像面</p>
                 
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('1') != -1"
                label="药品经营许可证:"
                prop="ptlPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload3"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.ptlPic"
                    :src="infoUrl + ruleForm.ptlPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                v-if="certificate && certificate.indexOf('2') != -1"
                label="药品生产许可证:"
                prop="pplPic"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="handleYyzzFileUpload4"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="ruleForm.pplPic"
                    :src="infoUrl + ruleForm.pplPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <!-- 个人 -->
        <el-form
          v-if="type == 4"
          ref="personForm"
          :model="personForm"
          :rules="rulesPerson"
          label-width="170px"
          :disabled="disabled"
        >
          <!-- 上 -->
          <div class="top">
            <el-form-item label="入驻类别:">
              <el-select
                v-model="ruleForm.gysType"
                placeholder="请先选择入驻类别"
                style="width: 100%"
                @change="changeSel"
              >
                <el-option
                  v-for="(item, index) in options1"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="申请人姓名:" prop="name">
                <el-input v-model="personForm.name"></el-input>
              </el-form-item>
              <!-- <el-form-item label="申请人手机号:" prop="personalphone">
                <el-input
                  v-model="personForm.personalphone"
                  disabled
                ></el-input>
              </el-form-item> -->

              <el-form-item label="申请人身份证号:" prop="identificationNum">
                <el-input v-model="personForm.identificationNum"></el-input>
              </el-form-item>
              <el-form-item
                label="上传身份证国徽面照片:"
                prop="identificationBackPictureUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  name="sfzb"
                  :limit="1"
                  :show-file-list="false"
                  :http-request="handleCnhFileUpload"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="personForm.identificationBackPictureUrl"
                    :src="infoUrl + personForm.identificationBackPictureUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="上传承诺函照片:"
                prop="commitmentLetterPictureUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  name="cnh"
                  :limit="1"
                  :show-file-list="false"
                  :http-request="handleCnhFileUpload"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="personForm.commitmentLetterPictureUrl"
                    :src="infoUrl + personForm.commitmentLetterPictureUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="申请人开户行:" prop="bank">
                <el-input v-model="personForm.bank"></el-input>
              </el-form-item>
              <el-form-item label="申请人银行账号:" prop="bankCode">
                <el-input v-model="personForm.bankCode"></el-input>
              </el-form-item>

              <el-form-item
                label="上传身份证人像面照片:"
                prop="identificationFrontPictureUrl"
              >
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  name="sfzz"
                  :limit="1"
                  :show-file-list="false"
                  :http-request="handleCnhFileUpload"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="personForm.identificationFrontPictureUrl"
                    :src="infoUrl + personForm.identificationFrontPictureUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> </el-form-item
            ></el-col>
          </el-row>
        </el-form>
        <div style="text-align: center;font-size:17px">如有问题请联系 <span style="color: #14639e;">17730369035</span> </div>
      </div>
      <div
        slot="footer"
        class="login-footer"
        v-if="checkFlag == -1 || checkFlag == 2"
      >
        <el-button type="primary" @click="loginBtn">提交审核</el-button>
      </div>
      <div
        slot="footer"
        class="login-footer"
        v-if="clkApply"
      >
        <el-button type="primary" @click="apply">申请入驻</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// register,
import { getSmsCode, updateUser, updatePerson, getUserInfo } from '@/api/modules'
// import Cookies from 'js-cookie'
import { uploadFile } from '@/api/modules/upload.js'
import { INFO_URL } from '@/config/globalConfig'
import { getDictInfo } from '@/api/modules'
import FromRules from '@/utils/fromRules'
export default {
  data() {
    return {
      infoUrl: INFO_URL,
      tipTxt: '尊敬的用户，提供资质合格才能使用更多功能。',
      userInfo: '',
      jinzhi: false,
      checkFlag: -1,
      fileList: [],
      disabled: false,
      clkApply: false,
      gysType: '',
      options1: [],
      options2: [],
      // 上传地址
      options: [
        {
          value: '1',
          label: '药品经营许可证',
        },
        {
          value: '2',
          label: '药品生产许可证',
        },
        {
          value: '3',
          label: 'GMP证书',
        },
        {
          value: '4',
          label: 'GSP证书',
        },
        {
          value: '5',
          label: '特殊品种经营许可证',
        },
        {
          value: '6',
          label: '其他',
        },
        {
          value: '7',
          label: '暂无证书',
        },
      ],
      // 注册表单
      ruleForm: {
        id: '',
        // 供应商类别
        gysType: 1,
        // 法人代表
        legal: '',
        // 税务登记证
        taxRegistPic: '',
        // 组织机构代码证
        orgCode: '',
        // 相关证书
        certificateTypeList: '',

        // 药品经营许可证
        ptlPic: '',
        // 药品生产许可证
        pplPic: '',
        // GMP证书
        gmpPic: '',
        // GSP证书
        gspPic: '',
        // 特殊品种经营许可证
        sptlPic: '',
        // 其他
        otherPic: '',
        // 用户类型
        type: '',
        // 供应商名称
        companyName: '',
        // 电话号码
        contactTel: '',
        // 营业执照代码
        yyzzCode: '',
        // 营业执照
        yyzzPic: '',

        // 委托人身份证
        idcadeno: '',
        // 身份证照
        idcadenoPics: '',
        // 授权委托人
        leader: '',
        // 委托人电话
        leaderTel: '',
        // 发票
        invoiceType: '',
        // 开票单位
        fpdw: '',
        // 单位地址
        fpdwAddr: '',
        // 开票税号
        fpdwSh: '',
        // 税收地手机号
        // dutyPhone: '',
        // 开户行
        bank: '',
        // 银行账号
        bankCode: '',
        // 授权委托书
        attorneyPic: '',
        // 承诺书
        commitmentPic: '',
      },
      // 注册表单验证
      rulesLogin: {
        gysType: [
          { required: true, message: '请选择入驻类别', trigger: 'change' },
        ],
        companyName: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
        legal: [{ required: true, message: '请输入法人代表', trigger: 'blur' }],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        yyzzCode: [
          { required: true, message: '请输入营业执照代码', trigger: 'blur' },
          { validator: FromRules.checkTaxNo, trigger: 'change' },
        ],
        orgCode: [
          { required: true, message: '请输入组织机构代码证', trigger: 'blur' },
        ],
        certificateTypeList: [
          { required: true, message: '请选择相关证书', trigger: 'change' },
        ],
        yyzzPic: [
          { required: true, message: '请上传营业执照', trigger: 'change' },
        ],
        ptlPic: [
          {
            required: true,
            message: '请上传药品经营许可证',
            trigger: 'change',
          },
        ],
        pplPic: [
          {
            required: true,
            message: '请上传药品生产许可证',
            trigger: 'change',
          },
        ],
        gmpPic: [
          {
            required: true,
            message: '请上传GMP证书',
            trigger: 'change',
          },
        ],
        gspPic: [
          {
            required: true,
            message: '请上传GSP证书',
            trigger: 'change',
          },
        ],
        sptlPic: [
          {
            required: true,
            message: '请上传特殊品种经营许可证',
            trigger: 'change',
          },
        ],
        otherPic: [
          {
            required: true,
            message: '请上传其他证',
            trigger: 'change',
          },
        ],

        idcadeno: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: FromRules.checkIdCard, trigger: 'change' },
        ],
        // invoiceType: [{ required: true, message: '请选择发票', trigger: 'change' }],
        contactTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        idcadenoPics: [
          { required: true, message: '请上传身份证', trigger: 'blur' },
        ],
        leader: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        fpdwSh: [
          { required: true, message: '请输入开票税号', trigger: 'blur' },
          { validator: FromRules.checkTaxNo, trigger: 'change' },
        ],
        leaderTel: [
          { required: true, message: '请输入负责人手机号', trigger: 'change' },
          { validator: FromRules.validatePhone, trigger: 'change' },
        ],
        invoiceType: [
          { required: true, message: '请选择发票', trigger: 'change' },
        ],
        fpdw: [{ required: true, message: '请输入开票单位', trigger: 'blur' }],
        fpdwAddr: [
          { required: true, message: '请输入单位地址', trigger: 'blur' },
        ],

        bank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        bankCode: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { validator: FromRules.checkBankCard, trigger: 'change' },
        ],
      },
      personForm: {
        // 开户行
        bank: '',
        // 银行账号
        bankCode: '',
        // 承诺函照片地址
        commitmentLetterPictureUrl: '',
        // 身份证背面照片地址
        identificationBackPictureUrl: '',
        // 身份证正面照片地址
        identificationFrontPictureUrl: '',
        // 身份证号
        identificationNum: '',
        // 手机号
        personalphone: '',
        // 姓名
        name: '',
      },
      // 个人验证
      rulesPerson: {
        commitmentLetterPictureUrl: [
          { required: true, message: '请选择承诺函照片', trigger: 'change' },
        ],
        identificationBackPictureUrl: [
          {
            required: true,
            message: '请选择身份证背面照片',
            trigger: 'change',
          },
        ],
        identificationFrontPictureUrl: [
          {
            required: true,
            message: '请选择身份证正面照片',
            trigger: 'change',
          },
        ],
        name: [
          { required: true, message: '请输入申请人名称', trigger: 'blur' },
        ],
        personalphone: [
          { required: true, message: '请输入申请人电话', trigger: 'blur' },
          // { validator: FromRules.validatePhone, trigger: 'change' },
        ],
        bank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        bankCode: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          // { validator: FromRules.checkBankCard, trigger: 'change' },
        ],
        identificationNum: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          // { validator: FromRules.checkIdCard, trigger: 'change' },
        ],
      },
      isShowTime: false,
      time: 60,
      timer: null,
      // 正面
      positive: '',
      // 反面
      back: '',
      // type: 1,
    }
  },
  computed: {
    certificate() {
      return this.ruleForm.certificateTypeList
        ? this.ruleForm.certificateTypeList
        : ''
    },
    type() {
      return this.ruleForm.gysType ? this.ruleForm.gysType : 1
    },
    // gsyTypeWatch() {
    //   return function (gysType) {
    //     if (gysType == null) {
    //       return ''
    //     }
    //     return this.options1[gysType]['label']
    //   }
    // },
  },
  mounted() {
    // this.getUserInfo()
  },
  created() {
    this.getGysType()
    this.getInvoiceType()
    this.getUserInfo()
  },
  methods: {
    getGysType() {
      getDictInfo('BID_GYS_TYPE').then((res) => {
        if (res.code == 200) {
          console.log(res, '类别')
          const map = new Map(Object.entries(res.data))
          this.options1 = this.mapToObj(map)
        }
      })
    },
    getInvoiceType() {
      getDictInfo('BID_INVOICE_TYPE').then((res) => {
        if (res.code == 200) {
          const map = new Map(Object.entries(res.data))
          this.options2 = this.mapToObj(map)
        }
      })
    },

    changeSel(e) {
      console.log(e, 'eeeeee')
    },

    mapToObj(strMap) {
      let arr = new Array()
      for (let [k, v] of strMap) {
        let obj = Object.create(null)
        obj.value = parseInt(k)
        obj.label = v
        arr.push(obj)
      }
      return arr
    },

    getUserInfo() {
      let that = this
      getUserInfo().then((res) => {
        let userInfo = res.data
        this.$store.commit('userInfo', res.data || {})
        this.ruleForm.id = res.data.id
        console.log('yonhuming2', res.data)
        // that.checkFlag = -1
        that.ruleForm.checkFlag = res.data.checkFlag
        that.checkFlag = res.data.checkFlag
        console.log(that.checkFlag, 'checkFlag')
        that.ruleForm.gysType = res.data.gysType
        if (res.data.gysType !== 4) {
          console.log(111)
          that.ruleForm = that.recover(that.ruleForm, res.data)
          console.log('2222222220.0.0.0.', that.ruleForm)
          let gysTypeObj = that.options1.find(
            (item) => item.value == res.data.gysType
          )
          that.ruleForm.gysType = gysTypeObj ? gysTypeObj.value : ''
          let invoiceTypeObj = that.options2.find(
            (item) => item.value == res.data.invoiceType
          )
          that.ruleForm.invoiceType = invoiceTypeObj ? invoiceTypeObj.value : ''
          if (res.data.idcadenoPics && res.data.idcadenoPics !== '') {
            let idPhoto = res.data.idcadenoPics.split(',')
            that.positive = idPhoto[0]
            that.back = idPhoto[1]
            console.log(that.positive, that.positive, 'that.fileList')
          }
        }
        if (res.data.gysType == '4') {
          console.log(222)
          that.personForm = that.recover(that.personForm, res.data)
          console.log('2222222220.0.0.0.', that.personForm)
          let gysTypeObj = that.options1.find(
            (item) => item.value == res.data.gysType
          )
          that.ruleForm.gysType = gysTypeObj ? gysTypeObj.value : ''
          // let invoiceTypeObj = that.options2.find(
          //   (item) => item.value == res.data.invoiceType
          // )
          // that.ruleForm.invoiceType = invoiceTypeObj ? invoiceTypeObj.value : ''
          // if (res.data.idcadenoPics && res.data.idcadenoPics !== '') {
          //   let idPhoto = res.data.idcadenoPics.split(',')
          //   that.positive = idPhoto[0]
          //   that.back = idPhoto[1]
          //   console.log(that.positive, that.positive, 'that.fileList')
          // }
        }
        if (that.checkFlag == 0) {
          that.tipTxt = '尊敬的会员，您的资质正在核实中，暂时无法编辑，请等候！'
          that.jinzhi = true
          that.disabled = true
        }
        if (that.checkFlag == 1) {
          that.tipTxt = '尊敬的会员，恭喜您，审核通过！'
          that.jinzhi = true
          that.disabled = true
        }
        if (that.checkFlag == 2) {
          that.tipTxt = '尊敬的会员，很遗憾审核未通过，请修改！'
          if (userInfo.auditReason != null && userInfo.auditReason != '') {
            that.tipTxt =
              that.tipTxt + ' 审核不通过原因：' + userInfo.auditReason
          }
        }
      })
      console.log(this.type, 'tyoe')
    },

    // 上传身份证
    handleIdcardFileUpload(fileObject) {
      console.log(this.ruleForm, 'sdsdsdsds')
      console.log('2222')
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      // this.upload(fd)
      uploadFile(fd)
        .then((res) => {
          console.log(res, this.ruleForm, 'sdsdsdsds')
          if (fileObject.filename=='sfzb') {
            if(this.ruleForm.idcadenoPics!=''){
                this.ruleForm.idcadenoPics =this.ruleForm.idcadenoPics + ',' + res.path
            }else{
              this.ruleForm.idcadenoPics = res.path
            }
            this.positive = res.path
            console.log(this.positive, 'this.positive')
          } else {
            if(this.ruleForm.idcadenoPics!=''){
                this.ruleForm.idcadenoPics =this.ruleForm.idcadenoPics + ',' + res.path
            }else{
              this.ruleForm.idcadenoPics = res.path
            }
            this.back = res.path
          }
          console.log(this.ruleForm.idcadenoPics)
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 营业执照
    handleCnhFileUpload(fileObject) {
      console.log(fileObject, 'fileObject')
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          if (fileObject.filename == 'cnh') {
            this.personForm.commitmentLetterPictureUrl = res.path
          } else if (fileObject.filename == 'sfzb') {
            this.personForm.identificationBackPictureUrl = res.path
          } else if (fileObject.filename == 'sfzz') {
            this.personForm.identificationFrontPictureUrl = res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 营业执照
    handleYyzzFileUpload(fileObject) {
      console.log(fileObject, 'fileObject')
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          if (this.ruleForm.yyzzPic === '') {
            this.ruleForm.yyzzPic = res.path
          } else {
            this.ruleForm.yyzzPic = res.path
          }
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 税务登记证
    handleYyzzFileUpload1(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.taxRegistPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 承诺书
    handleYyzzFileUpload2(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.commitmentPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 药品经营许可证
    handleYyzzFileUpload3(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.ptlPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 药品生产许可证
    handleYyzzFileUpload4(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.pplPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // GMP证书
    handleYyzzFileUpload5(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.gmpPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // GSP证书
    handleYyzzFileUpload6(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.gspPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 特殊品种经营许可证
    handleYyzzFileUpload7(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.sptlPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 其他
    handleYyzzFileUpload8(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.otherPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    // 其他
    handleYyzzFileUpload9(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          this.ruleForm.attorneyPic = res.path
        })
        .catch((exp) => {
          console.log(exp)
        })
        .finally(() => {})
    },

    remove(file, fileList) {
      console.log('删除照片', file, fileList)
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log(URL.createObjectURL(file.raw), '222222')
      // this.ruleForm.yyzzPic = URL.createObjectURL(file.raw)
    },
    // 上传图片限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
      }
      return isLt2M
    },

    // 获取验证码
    getSmsCode() {
      if (this.isShowTime) {
        return
      }
      this.isShowTime = true
      this.time = 60
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time -= 1
        } else {
          this.isShowTime = false
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)

      getSmsCode({
        bizType: 'register',
        phone: this.ruleForm.phone,
      }).then((res) => {
        if (res.code == 200) {
          // this.form.smsCode = JSON.parse(res.data).code || ''
          this.$message.warning('发送成功')
          console.log('发送成功')
        } else {
          this.$message.warning(res.msg)
          // this.isShowTime = false
          //  clearInterval(this.timer)
        }
      })
    },
    // 认证api
    updateUser() {
      updateUser(this.ruleForm).then((res) => {
        console.log('提交表单', this.ruleForm)
        if (res.code == 200) {
          this.$message.success('提交成功')
          console.log('提交成功', res)
          this.$router.push({
            name: 'bid',
          })
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    apply(){
      
    },
    loginBtn() {
      if (this.type === 4) {
        this.$refs.personForm.validate((valid) => {
          if (valid) {
            console.log(this.personForm, '个人')
            // this.updateUser()
            updatePerson(this.personForm)
              .then((res) => {
                console.log(res, '提交状态')
                if (res.code == 200) {
                  this.$message.success('提交成功')
                  this.$router.push({
                    name: 'bid',
                  })
                }
              })
              .catch((res) => {
                this.$message({
                  message: res.msg,
                  type: 'warning',
                })
              })
          } else {
            this.$message({
              message: '请正确填写信息',
              type: 'warning',
            })
          }
        })
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            console.log(this.ruleForm, '企业')
            this.updateUser()
          } else {
            return false
          }
        })
      }
    },
    clearTime() {
      this.timer && clearInterval(this.timer)
      this.timer = null
      this.isShowTime = false
    },
    clearFiles() {
      console.log('shijian')
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

/deep/ .el-upload--text {
  text-align: left !important;
}
.banxin {
  max-width: 1190px;
  margin: 0 auto;
  margin-top: 40px;
  .form-style-logon {
    padding: 0 20px;

    .icon-login {
      font-size: 20px;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 20px;
    }

    .code-style {
      display: flex;
      align-items: center;
    }
  }
  /deep/ .el-button.is-round {
    text-align: center;
    color: rgb(20, 99, 158);
    cursor: pointer;
    border: 1px solid #14639e;
    margin-left: 5px;
    padding: 12px 18px;
  }
  .form-style-login {
    // width: 1000px;
    padding: 0 20px;
    margin-bottom: 40px;

    .icon-login {
      font-size: 20px;
    }

    .avatar-uploader .el-upload {
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      border: 1px dashed #acacac;
      font-size: 28px;
      color: #8c939d;
      width: 150px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    \deep\.el-upload {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .avatar {
      width: 150px;
      height: 100px;
      display: block;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 20px;
    }

    .code-style {
      display: flex;
      align-items: center;
    }
  }

  &-footer {
    padding: 0 20px;
    text-align: center;

    .login-btn {
      width: 100%;
    }

    .login-tab {
      margin: 16px 0 8px;
      font-size: 13px;

      span {
        color: rgb(20, 99, 158);
        cursor: pointer;
      }
    }
  }
  .login-footer {
    text-align: center;
    margin-bottom: 20px;
    /deep/ .el-button {
      padding: 12px 80px;
      cursor: pointer;
    }
  }
}
.top {
  width: 550px;
  margin: 10px auto 35px;
}
.tags {
  margin-bottom: 30px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #ffefd8;
  color: #eb8900;
  text-align: center;
  line-height: 60px;
  span {
    font-size: 18px;
  }
}
.tabs {
  margin-bottom: 30px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #deffd8;
  color: #2feb00;
  text-align: center;
  line-height: 60px;
  span {
    font-size: 18px;
  }
}

.fags {
  margin-bottom: 30px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #6d6d6d;
  text-align: center;
  line-height: 60px;
  span {
    font-size: 18px;
  }
}
</style>
