<template>
  <div>
    <!-- 产品维护 -->

    <el-form inline>
      <el-form-item><el-input v-model="searForm.name" placeholder="请输入品名"></el-input></el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button type="success" @click="showAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border>
      <el-table-column label="序号" type="index" width="55" :index="indexMethod"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="地址" width="200">
        <template slot-scope="scope">{{scope.row.provinceName + scope.row.cityName + scope.row.areaName}}</template>
      </el-table-column>
      <!-- <el-table-column label="详细地址" width="300" prop="addr" /> -->
      <el-table-column label="产品介绍" prop="descriptions" width="300px">
        <template slot-scope="scope">
          <p v-if="scope.row.descriptions.length>40">{{scope.row.descriptions.slice(0, 40) + '...'}}</p>
          <p v-else>{{scope.row.descriptions}}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.approveStatus === 1">待审核</span>
          <span v-if="scope.row.approveStatus === 2">审核通过</span>
          <span v-if="scope.row.approveStatus === 3">审核不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template slot-scope="scope">
          {{scope.row.createDate | dateFormat}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-link class="mr-5 ml-5" type="primary" v-if="scope.row.approveStatus > 1" @click="edit(scope.row.id)">编辑</el-link>
          <el-link class="mr-5 ml-5" type="primary" @click="look(scope.row.id)">查看</el-link>
          <el-link class="mr-5 ml-5" type="danger" v-if="scope.row.approveStatus > 1" @click="deletePro(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>

    <div class="page-bar">
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <el-dialog :title="alertTitle" :visible.sync="showAlertFlag" :close-on-click-modal="false" @close="cancel">
      <el-form label-width="150px" ref="formRef" :disabled="disabledForm" :model="form" :rules="formRules">
        <el-form-item label="名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入品名"></el-input>
            </el-form-item>

        <el-form-item label="地址">
          <el-select v-model="form.province" style="width: 32%;margin-right:2%" @change="changeProvince">
            <el-option v-for="(item, index) in provinceList" :key="index" :label="item.xzqhmc" :value="item.xzqhdm">
            </el-option>
          </el-select>
          <el-select v-model="form.city" style="width: 32%;margin-right:2%" @change="changeCity">
            <el-option v-for="(item, index) in cityList" :key="index" :label="item.xzqhmc" :value="item.xzqhdm">
            </el-option>
          </el-select>
          <el-select v-model="form.area" style="width: 32%" @change="changeArea">
            <el-option v-for="(item, index) in areaList" :key="index" :label="item.xzqhmc" :value="item.xzqhdm">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="form.addr" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="产品主图" ref="mainItemRef" prop="mainpic">
          <el-upload ref="mainUploadRef" :class="disabledForm||(mainpics.length>0)?'disabled':''" :action="uploadOneImgUrl" list-type="picture-card"
            :limit="1" :file-list="fileListObj.mainpic" :on-preview="handlePictureCardPreview" :on-remove="handleMainRemove"
            :on-success="handleMainRes">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="产品详图" prop="picsurl">
          <el-upload ref="detailRef" :class="disabledForm?'disabled':''" :action="uploadOneImgUrl" list-type="picture-card" :limit="9"
            :file-list="fileListObj.picsurl" :on-preview="handlePictureCardPreview" :on-remove="handleDetailRemove" :on-success="handleDetailRes">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="产品介绍">
          <el-input type="textarea" v-model="form.descriptions" placeholder="请输入描述"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getXzqh } from '@/api/modules/release'
import dayjs from 'dayjs'
import { editProduct, uploadOneImg, delPro, getPros, getProDetail } from '@/api/modules/personalCenter.js'
import { INFO_URL } from '@/config/globalConfig'

export default {
  name: 'productFix',
  data() {
    return {
      baseUrl: INFO_URL,
      searForm: {
        name: '',
        pageIndex: 1,
        pageSize: 10
      },
      // 上传
      uploadMainClass: '',
      uploadDetailClass: '',
      showAlertFlag: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabledForm: false,
      form: {
        id: '',
        name: '',
        province: '',
        city: '',
        area: '',
        addr: '',
        mainpic: '',
        picsurl: '',
        descriptions: ''
      },
      formRules: {
        name: [{ required: true, message: '请输入品名', trigger: 'blur' }],
        mainpic: [{ required: true, message: '请上传产品主图', trigger: 'change' }]
      },
      fileListObj: {
        mainpic: [],
        picsurl: []
      },
      mainpics: [],
      picsurls: [],
      alertTitle: '',
      tableData: [],
      total: 0,
      uploadOneImgUrl: '',
      provinceList: [],
      cityList: [],
      areaList: []
    }
  },
  created() {
    this.uploadOneImgUrl = uploadOneImg()
    this.getXzqh()
    this.search()
  },
  filters: {
    dateFormat(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    // 查询
    search() {
      this.searForm.pageIndex = 1
      this.getPros()
    },
    async getPros() {
      const res = await getPros(this.searForm)
      if (res.code === 5000) {
        this.$message.warning('请先填写公司信息')
        this.$router.push('/joinApply')
        return
      }
      if (res.data) {
        this.tableData = res.data.content
        this.total = res.data.totalElements
      }
    },
    indexMethod(index) {
      const { pageIndex, pageSize } = this.searForm
      return index + 1 + (pageIndex - 1) * pageSize
    },
    // 重置
    reset() {
      this.searForm.name = ''
    },
    // 新增
    showAdd() {
      this.alertTitle = '新增产品'
      this.showAlertFlag = true

      this.disabledForm = false
    },
    // 编辑
    async edit(id) {
      await this.getDetail(id)
      this.alertTitle = '编辑产品'
      this.showAlertFlag = true
      this.disabledForm = false
    },
    async getDetail(id) {
      const res = await getProDetail({ id })

      if (res.code === 200) {
        const { mainpic, picsurl } = res.data
        if (mainpic) {
          this.fileListObj.mainpic = [{ name: '', url: this.baseUrl + mainpic, path: mainpic }]
          this.mainpics = [mainpic]
        }
        if (picsurl) {
          const arr = picsurl.split(',')
          this.fileListObj.picsurl = arr.map((item) => {
            return {
              name: '',
              url: this.baseUrl + item,
              path: item
            }
          })
          this.picsurls = arr
        }
        if (res.data.province) {
          await this.changeProvince(res.data.province)
        }
        if (res.data.city) {
          await this.changeCity(res.data.city)
        }

        this.form = {
          id: res.data.id,
          name: res.data.name,
          spec: res.data.spec,
          province: res.data.province,
          provinceName: res.data.provinceName,
          city: res.data.city,
          cityName: res.data.cityName,
          area: res.data.area,
          areaName: res.data.areaName,
          addr: res.data.addr,
          mainpic: res.data.mainpic,
          picsurl: res.data.picsurl,
          descriptions: res.data.descriptions
        }
      }
    },
    // 查看
    async look(id) {
      await this.getDetail(id)
      this.alertTitle = '查看产品'
      this.disabledForm = true
      this.showAlertFlag = true
    },
    // 删除
    async deletePro(id) {
      const res = await delPro({ ids: id })
      if (res.code === 200) {
        this.$message.success(res.msg)
        this.search()
      }
    },
    // 保存
    async save() {
      this.$refs.formRef.validate(async (pass) => {
        if (pass) {
          if (this.picsurls.length > 0) {
            this.form.picsurl = this.picsurls.join(',')
          }

          const res = await editProduct(this.form)
          if (res.code !== 200) {
            this.$message.warning(res.msg)
            return
          }
          this.$message.success(res.msg)
          this.cancel()
          this.search()
        }
      })
    },
    // 取消
    cancel() {
      this.form = this.$options.data().form
      this.mainpics = []
      this.picsurls = []
      this.$refs.mainUploadRef.clearFiles()
      this.$refs.detailRef.clearFiles()
      this.showAlertFlag = false
    },
    // 上传图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleMainRemove() {
      this.mainpics = []
    },
    handleDetailRemove(file) {
      const index = this.picsurls.findIndex((item) => item === file.path)
      this.picsurls.splice(index, 1)
    },
    handleMainRes(res) {
      this.mainpics = [res.data.path]
      this.form.mainpic = res.data.path
      this.$refs.mainItemRef.clearValidate()
    },
    handleDetailRes(res) {
      this.picsurls.push(res.data.path)
    },
    // 省市区选择
    getXzqh() {
      let params = {
        level: 2
      }
      getXzqh(params).then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          })
        }
      })
    },
    async changeProvince(val) {
      let obj = this.provinceList.find((item) => {
        return item.xzqhdm == val
      })
      this.form.provinceName = obj.xzqhmc
      this.form.city = ''
      this.form.area = ''
      this.areaList = []
      let params = {
        parentCode: val,
        level: 3
      }
      const res = await getXzqh(params)
      if (res.code == 200) {
        this.cityList = res.data
      } else {
        this.$notify({
          title: '信息',
          message: '请先登录以获取更多信息',
          type: 'info',
          offset: 100,
          duration: 2600
        })
      }
    },
    changeCity(val) {
      let obj = this.cityList.find((item) => {
        return item.xzqhdm == val
      })
      this.form.cityName = obj.xzqhmc
      this.form.area = ''
      let params = {
        parentCode: val,
        level: 4
      }
      getXzqh(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          })
        }
      })
    },
    changeArea(val) {
      let obj = this.areaList.find((item) => {
        return item.xzqhdm == val
      })
      this.form.areaName = obj.xzqhmc
      this.form.area = val
    }
  }
}
</script>

<style scoped>
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.page-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
</style>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>