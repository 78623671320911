<template>
  <div class="main">
    <div class="main-mid">
      <h1>产品展示</h1>
      <div class="main_d2" v-if="productList.length > 0">
        <div class="mid-li" v-for="(item, index) in productList" :key="index">
          <div style="width: 158px; height: 158px;" @click="toDetail(item, item.goodtype)">
            <!-- <el-image
              :src="'/info-admin' + item.picsurl"
              style="width: 148px; height: 148px; cursor: pointer"
            ></el-image> -->
            <el-carousel
              trigger="click"
              height="158px"
              v-if="item.picsurl"
            >
              <el-carousel-item v-for="(item, index) in item.picsurl.split(',')" :key="index">
                <img style="width: 158px; height: 158px;" :src="baseUrl  + item" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <h2 class="proName" @click="toDetail(item, item.goodtype)">
            {{ item.name }}
          </h2>
          <p>规格：{{ item.spec }}</p>
          <p>数量：{{ item.stockcount }}</p>
          <p>产地：{{ item.addr }}</p>
          <p style="color: red;">价格：{{ item.price }}</p>
          <p style="color: red;">剩余：{{ item.deadline }}天</p>
        </div>
      </div>
      <div style="background: #F2F2F5" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <div class="main-mid">
      <h1>店主信息</h1>
      <div class="zg-content">
        <ul>
          <li>联系人：{{ parlourForm.company_name }}</li>
          <li>电 话：{{ parlourForm.contact_tel }}</li>
          <!-- <li>
              实名认证： {{ parlourForm.is_real_name == 1 ? '是' : '否' }}
            </li> -->
          <li>实名认证：是</li>
          <li>手 机：{{ parlourForm.personalphone }}</li>
          <li>地 址：{{ parlourForm.address }}</li>
          <li>详细地址：{{ parlourForm.addressmore }}</li>
          <li>浏览量：{{ parlourForm.pageview }}</li>
          <li>创店时间：{{ parlourForm.s_ms_date }}</li>
        </ul>

        <div class="zg-head">
          <div style="width: 100px; height: 100px">
            <img
              src="../../assets/imgs/head.gif"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="main-mid">
      <h1>店铺公告</h1>
      <div class="content">
        <p>{{ parlourForm.description }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import { INFO_URL } from '@/config/globalConfig'
import { getById } from "@/api/modules/gq";
import { getGoods } from "@/api/modules/myShop";
import { saveView } from "@/api/modules/index";
import {
  getUserInfo
} from '@/api'
export default {
  props: {
    name: {
      type: String,
      default: 'first',
    },
    username: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: undefined
    }

  },
  data () {
    return {
      baseUrl: INFO_URL,
      productList: [],
      parlourForm: {},
      personalphone: '',
    }
  },

  computed: {

  },
  created () {
    this.getInfo()

  },
  methods: {
    getInfo () {
      this.getMyShop()
      this.getDetail()
    },
    getMyShop () {
      getById({ username: this.username }).then(res => {
        if (res.code == 200) {
          this.parlourForm = res.data
        }
      })
    },

    getDetail () {
      const form = {
        publisherUsername: this.username,
        type: this.type
      }
      getGoods(form).then(res => {
        if (res.code == 200) {
          this.productList = res.data
        }
      })
    },
    toDetail (item, type) {



      this.$router.push({
        name: "gqDetail",
        query: {
          id: item.id,
          type: type
        },
      });
    },
  }
}
</script>

<style lang="less" scoped>
.main {
  .main-top {
    display: flex;
  }
  .main-mid {
    margin: 0 auto;
    margin-top: 30px;
    h1 {
      margin-bottom: 15px;
    }
    .zg-content {
      width: 990px;
      height: 380px;
      margin-top: 1px;
      background: #F2F2F5;
      padding-top: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      ul {
        padding: 20px 0 0 80px;
        li {
          line-height: 40px;
        }
      }
      .zg-head {
        padding: 20px 0 0 80px;
      }
      p {
        width: 470px;
        line-height: 150%;
        margin: 10px;
        height: 320px;
        overflow: hidden;
      }
    }
    .content {
      width: 990px;
      height: 180px;
      margin-top: 1px;
      background: #F2F2F5;
      padding-top: 8px;
    }
    .main_d2 {
      width: 990px;
      margin-top: 1px;
      padding-top: 20px;
      background: #F2F2F5;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      // grid-column-gap: 40px;
      // grid-row-gap: 34px;
      // padding-right: 10px;
      .mid-li {
        width: 150px;
        height: auto;
        margin: 13px 0 10px 13px;

        h2 {
          cursor: pointer;
          width: 150px;
          height: 24px;
          line-height: 24px;
          margin-top: 3px;
          &:hover {
            outline: 0;
            text-decoration: underline;
          }
        }
        p {
          font: normal 12px/22px '宋体';
          color: #333;
        }
      }
    }
  }
  h1 {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    background: #00bbe1;
  }
}
</style>
