<template>
  <div class="water-title">
    <h2>{{Chinese}}</h2>
    <div class="water">{{English}}</div>
  </div>
</template>

<script>
export default {
  mixins: [],
  props: {
    Chinese: String,
    English: String
  },
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang='scss' scoped>
.water-title {
  display: flex;
  justify-content: center;
  position: relative;
  h2 {
    font-size: 32px;
    font-weight: 700;
    height: 60px;
    position: relative;
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 32px;
      height: 4px;
      background-color: #0054e4;
    }
  }
  .water {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    font-size: 36px;
    font-weight: 700;
    color: #F3F5F8;
    text-shadow: #F3F5F8 1px 0 0, #F3F5F8 0 1px 0, #F3F5F8 -1px 0 0, #F3F5F8 0 -1px 0;
  }
}
</style>
