const prefix = 'info-admin/api'
export default ({ request }) => ({
  // 获取招标列表
  getAllInquiryList(params, data = {}) {
    return request({
      url: prefix + '/mall/yshInquiry/getAllInquiryList',
      method: 'post',
      data,
      params
    })
  },
  // 最新中标状态 https://test.boyaocaigou.com/info-admin/api/mall/yshInquiryMU/getInquiryUser/20 GET
  getInquiryUser(id) {
    return request({
      url: prefix + '/mall/yshInquiryMU/getInquiryUser/' + id,
      method: 'get'
    })
  },
  // 市场价格详情
  getHisPrice(data = {}) {
    return request({
      url: prefix + '/ysc/yscPrice/pc/getHisPrice',
      method: 'post',
      data
    })
  },
  // 产地列表
  getAddrAndSpec(params = {}) {
    return request({
      url: prefix + '/mall/yshSupply/getAddress',
      method: 'post',
      params
    })
  },
  // https://test.boyaocaigou.com/info-admin/api/mall/yshSupply/getAddress?name=查询的药材名称
  // 市场价格列表：
  // https://test.boyaocaigou.com/info-admin/api/ysc/yscPrice/getAllMaterial post
  getYscPriceAllMaterial(data = {}) {
    return request({
      url: prefix + '/ysc/yscPrice/getAllMaterial',
      method: 'post',
      data
    })
  },
  // 供应列表：
  // https://test.boyaocaigou.com/info-admin/api/mall/yshSupply/getList post
  getYshSupplyList(data = {}) {
    return request({
      url: prefix + '/mall/yshSupply/getList',
      method: 'post',
      data,
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
  // 求购
  getPleaseBuy(data = {}) {
    return request({
      url: prefix + '/mall/yshPurchase/getList',
      method: 'post',
      data,
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
  // /api/mall/yshInquiryM/getList/20
  getYshInquiryMList(id) {
    return request({
      url: prefix + '/mall/yshInquiryM/getList/' + id,
      method: 'get'
    })
  },
  getYshInquiryInquiryList(params = {}) {
    return request({
      url: prefix + '/mall/yshInquiry/getInquiryList',
      method: 'post',
      data: {},
      params
    })
  },
  // 中标/结果公示列表：只按照标题查询，状态都已经是中标了按照状态查询没有意义。
  // https://test.boyaocaigou.com/info-admin/api/mall/yshClarification/list
  getYshClarificationList(data = {}) {
    return request({
      url: prefix + '/mall/yshClarification/list',
      method: 'post',
      data
      // params
    })
  },
  // ysc/yscPrice/getAllMcate
  getYscPriceAllMcate() {
    return request({
      url: prefix + '/ysc/yscPrice/getAllMcate',
      method: 'get'
    })
  },
  getYscPmList(params = {}) {
    return request({
      url: prefix + '/ysc/getPmList',
      method: 'get',
      params
    })
  },
  getYscStockList(params = {}) {
    return request({
      url: prefix + '/ysc/getStockList',
      method: 'get',
      params
    })
  },
  // https://test.boyaocaigou.com/info-admin/api/indexconfig/getDictInfo?type=BID_GYS_TYPE
  getDictInfo() {
    return request({
      url: prefix + '/indexconfig/getDictInfo?type=BID_GYS_TYPE',
      method: 'get'
      // params
    })
  },
  // mall/yshNews2/list
  getYshNews2List(data = {}) {
    return request({
      url: prefix + '/mall/yshNews2/list',
      method: 'post',
      data
      // params
    })
  },
  // riseAndFall/getRiseFallData
  getRiseFallData(data = {}) {
    return request({
      url: prefix + '/riseAndFall/getRiseFallData',
      method: 'post',
      data
      // params
    })
  },
  // mall/yshNews3/todetail?id=9
  toYshNews3Detail(id) {
    return request({
      url: prefix + '/mall/yshNews3/todetail?id=' + id,
      method: 'get'
    })
  },
  // pageView/getViewCount?viewType=2&relateId=9
  getYshNews3ViewCount(id) {
    return request({
      url: prefix + '/pageView/getViewCount?viewType=2&relateId=' + id,
      method: 'get'
    })
  },
  // api/recommend/getHotMaterial
  getHotMaterial() {
    return request({
      url: prefix + '/recommend/getHotMaterial',
      method: 'get'
    })
  },
  // api/recommend/getHotShop
  getHotShop() {
    return request({
      url: prefix + '/recommend/getHotShop',
      method: 'get'
    })
  },
  // mall/yshUser/getById
  getYshUserById(data = {}) {
    return request({
      url: prefix + '/mall/yshUser/getById',
      method: 'post',
      data,
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
  // mall/yshSupply/getSupplyInfo
  getSupplyInfo(data = {}) {
    return request({
      url: prefix + '/mall/yshSupply/getSupplyInfo',
      method: 'post',
      data,
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
  // yshPurchase/getPurchaseInfo
  getPurchaseInfo(data = {}) {
    return request({
      url: prefix + '/mall/yshPurchase/getPurchaseInfo',
      method: 'post',
      data,
      headers: {'Content-Type': 'multipart/form-data'}
    })
  },
})
