import Vue from 'vue'

// BID_STATUS 招标状态 :0:已发起,1:开标中,2:二次报价,3:评审中,4:已中标,5:招标审核中,6:招标审核不通过,7:投标中,8:已结束,-1: 审核不通过,-2: 流标,-3: 废标
Vue.filter('BID_STATUS', function(value) {
  switch (value) {
    case 0:
      return '已发起'
    case 1:
      return '开标中'
    case 2:
      return '二次报价'
    case 3:
      return '评审中'
    case 4:
      return '已中标'
    case 5:
      return '招标审核中'
    case 6:
      return '招标审核不通过'
    case 7:
      return '投标中'
    case 8:
      return '已结束'
    case -1:
      return '审核不通过'
    case -2:
      return '流标'
    case -3:
      return '废标'
    default:
      return '';
  }
})
// 3：变更公告 1、澄清公告
Vue.filter('noticeType', function(value) {
  switch (value) {
    case 3:
      return '变更公告'
    case 1:
      return '澄清公告'
    default:
      return '';
  }
})