import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/login.vue'
import Register from '@/views/login/register.vue'
import Home from '@/views/Home.vue'
import PersonalCenter from '@/views/personalCenter/personalCenter.vue'
import ProductFix from '@/views/personalCenter/productFix.vue'
import CompanyProfile from '@/views/personalCenter/companyProfile.vue'
import Qualifications from '@/views/qualifications/index'
import Qualifications2 from '@/views/qualifications/index2'
import Notice from '@/views/notice'
import Comment from '@/views/comment'
import Release from '@/views/release'
import BidManagement from '@/views/bid/bidManagement'
import Agreement from '@/views/agreements'
import BzjPay from '@/views/service/serviceComponents/bzjPay2.vue'
import MyShop from '@/views/myShop/index.vue'
import GqDetail from '@/views/service/serviceComponents/gqDetail.vue'
import JoinApply from '@/views/sections/joinApply.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/gqDetail',
        name: 'gqDetail',
        component: GqDetail
    },
    {
        path: '/myShop',
        name: 'myShop',
        component: MyShop
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    }, {
        path: '/',
        name: 'Home',
        redirect: "/index"
    },
    {
        path: '/index',
        name: 'Home',
        component: Home,
        meta: {
            keepAlive: true //需要被缓存
        }
    },
    {
        path: '/intro',
        name: 'Intro',
        component: () =>
            import ('../views/Intro.vue')
    },
    {
        path: '/businessSector',
        name: 'BusinessSector',
        component: () =>
            import ('../views/BusinessSector.vue')
    },
    {
        path: '/productDisplay',
        name: 'ProductDisplay',
        component: () =>
            import ('../views/ProductDisplay.vue')
    },
    {
        path: '/development',
        name: 'Development',
        component: () =>
            import ('../views/Development.vue')
    },
    {
        path: '/serviceHall',
        name: 'ServiceHall',
        component: () =>
            import ('../views/service/serviceHall.vue'),
    },
    {
        path: '/serviceHallMoreList',
        name: 'ServiceHallMoreList',
        component: () =>
            import ('../views/service/serviceComponents/serviceHallMoreList.vue')
    },
    {
        path: '/serviceHallDetail',
        name: 'ServiceHallDetail',
        component: () =>
            import ('../views/service/serviceComponents/serviceHallDetail.vue')
    },
    {
        path: '/serviceTender',
        name: 'ServiceTender',
        component: () =>
            import ('../views/service/ServiceTender.vue')
    },
    {
        path: '/SupplyDemand/:id?',
        name: 'SupplyDemand',
        component: () =>
            import ('../views/service/SupplyDemand.vue')
    },
    {
        path: '/bidDetail/:id?',
        name: 'BidDetail',
        component: () =>
            import ('../views/service/serviceComponents/bidDetail.vue')
    },
    {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () =>
            import ('../views/service/serviceComponents/noticeDetail.vue')
    },
    {
        path: '/servicePrice',
        name: 'ServicePrice',
        meta: {
            keepAlive: true //需要被缓存
        },
        component: () =>
            import ('../views/service/ServicePrice.vue')
    },
    {
        path: '/serviceArticle/:id?',
        name: 'ServiceArticle',
        component: () =>
            import ('../views/service/ServiceArticle.vue')
    },
    {
        path: '/marketDetail',
        name: 'MarketDetail',
        component: () =>
            import ('../views/service/MarketDetail.vue')
    },
    {
        path: '/supplyDemand/:id?',
        name: 'SupplyDemand',
        component: () =>
            import ('../views/service/SupplyDemand.vue')
    },
    {
        path: '/supplyDemandDetail/:type?/:id?',
        name: 'SupplyDemandDetail',
        component: () =>
            import ('../views/service/SupplyDemandDetail.vue')
    },
    {
        path: '/cooper',
        name: 'Cooper',
        component: () =>
            import ('../views/Cooper.vue')
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: PersonalCenter,
        children: [
            {
                path: '/bzjPay',
                name: 'bzjPay',
                component: BzjPay
            },
            {
                path: '/qualifications',
                name: 'qualifications',
                component: Qualifications
            },
            {
                path: '/qualifications2',
                name: 'qualifications2',
                component: Qualifications2
            },
            {
                path: '/notice',
                name: 'notice',
                component: Notice
            },
            {
                path: '/comment',
                name: 'comment',
                component: Comment
            },
            {
                path: '/release',
                name: 'release',
                component: Release
            },
            {
                path: '/bidManagement',
                name: 'bidManagement',
                component: BidManagement
            },
            {
                path: '/agreement',
                name: 'agreement',
                component: Agreement
            },
            {
                path: '/productFix',
                name: 'productFix',
                component: ProductFix
            },
            {
                path: '/companyProfile',
                name: 'companyProfile',
                component: CompanyProfile
            },
            {
                path: '/joinApply',
                name: 'joinApply',
                component: JoinApply
            }
        ]
    },
]

const router = new VueRouter({
    routes,
    base: process.env.NODE_ENV === "production" ? "/portal" : '',
})

export default router