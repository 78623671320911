<template>
<Wrapper>
  <div class="layout">
    <div class="personal-center">
      <aside>
        <ul class="personal-center__menu">
          <li @click="navigateTo('qualifications')" :class="urlName==='qualifications'?'active': ''">
            <i class="el-icon-document-copy"></i>我的资质
          </li>
          <li @click="navigateTo('qualifications2')" :class="urlName==='qualifications2'?'active': ''"><i class="el-icon-s-shop"></i>我的店铺</li>
          <li @click="navigateTo('notice')" :class="urlName==='notice'?'active': ''"><i class="el-icon-microphone"></i>修改公告</li>
          <li @click="navigateTo('comment')" :class="urlName==='comment'?'active': ''"><i class="el-icon-chat-dot-round"></i>留言列表</li>
          <li @click="navigateTo('release')" :class="urlName==='release'?'active': ''"><i class="el-icon-s-promotion"></i>供求发布</li>
          <li @click="navigateTo('bidManagement')" :class="urlName==='bidManagement'?'active': ''"><i class="el-icon-s-management"></i>我的投标</li>
          <li @click="navigateTo('agreement')" :class="urlName==='agreement'?'active': ''"><i class="el-icon-s-check"></i>我的合同</li>
          <li @click="navigateTo('productFix')" :class="urlName==='productFix'?'active': ''"><i class="el-icon-goods"></i>产品维护</li>
          <li @click="navigateTo('bzjPay')" :class="urlName==='bzjPay'?'active': ''"><i class="el-icon-goods"></i>保证金支付</li>
          <li @click="navigateTo('companyProfile')" :class="urlName==='companyProfile'?'active': ''">
            <i class="el-icon-school"></i>公司简介
          </li>
          <li @click="signOut"><i class="el-icon-s-release"></i>退出登录</li>
          <li @click="cancelAccount"><i class="el-icon-s-release"></i>注销账号</li>
        </ul>
      </aside>
      <main>
        <p v-if="urlName === 'personalCenter'">欢迎来到个人中心！</p>
        <router-view />
      </main>
    </div>
  </div>
</Wrapper>
</template>

<script>
import Wrapper from '@/components/Wrapper.vue'
import DetailBanner from '../sections/DetailBanner.vue'
import { getUserInfo, logOff } from '@/api/modules'
import { getCompanyByUser } from '@/api/modules/personalCenter.js'
import Cookies from 'js-cookie'
import store from '../../store'

export default {
  name: 'personalCenter',
  components: {
    Wrapper,DetailBanner
  },
  data() {
    return {
      urlName: '',
      checkFlag: -2,
    }
  },
  created() {
    this.urlName = this.$route.name
    this.getUserInfo()
  },
  methods: {
    async navigateTo(name) {
      // 判断资质认证情况，如无，跳转去资质申请页
      const needZiZhi = ['bidManagement', 'companyProfile', 'productFix'].indexOf(name) >= 0
      if (needZiZhi) {
        if (this.checkFlag == -1) {
          // console.log(555)
          this.$message.warning('您还没有认证资质，请认证资质哦！')
          setTimeout(() => {
            this.urlName = 'qualifications'
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return false
        } else if (this.checkFlag == 0) {
          this.$message.warning('您的资质正在审核中，请等候！')
          setTimeout(() => {
            this.urlName = 'qualifications'
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return false
        } else if (this.checkFlag == 2) {
          this.$message.warning('您的资质认证未通过，请重新认证！')
          this.urlName = 'qualifications'
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return false
        }
      }
      // 对于产品维护页，需要判断企业入驻情况，如无，不可跳转
      if (name === 'productFix' || name === 'companyProfile') {
        const res = await getCompanyByUser()
        const { status } = res.data
        let needGoToApply = true

        if (status === 1) {
          this.$message.warning('您的入驻申请正在审核中，请等候！')
        } else if (status === 2) {
          // 审核通过
          needGoToApply = false
        } else if (status === 3) {
          this.$message.warning('您的入驻申请未通过，请重新申请！')
        } else {
          this.$message.warning('您尚未提交入驻申请，请先入驻！')
        }

        if (needGoToApply) {
          setTimeout(() => {
            this.$router.push({
              name: 'joinApply'
            })
          }, 1000)
        } else {
          // 审核通过
          this.urlName = name
          setTimeout(() => {
            this.$router.push({
              name
            })
          }, 1000)
        }
        return false
      }

      this.urlName = name
      this.$router.push({
        name
      })
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.$store.commit('userInfo', res.data || {})
        if (res.code == 200) {
          this.checkFlag = res.data.checkFlag
        }
      })
    },
    signOut() {
      // store.dispatch('logout');
      this.$router.push({
        path: '/'
      })
      Cookies.remove('token')
      localStorage.removeItem('token')
      store.commit('userInfo', {})
      // 退出登录
      this.$message({
        message: '退出成功！',
        type: 'success'
      })
      location.reload()
    },
    cancelAccount() {
      this.$confirm('确定注销账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          logOff({}).then((res) => {
            if (res.code == 200) {
              this.$message.success('账号注销成功')
              this.$router.push({
                path: '/'
              })
              Cookies.remove('token')
              localStorage.removeItem('token')
              store.commit('userInfo', {})
              // // 退出登录
              // this.$message({
              //   message: '退出成功！',
              //   type: 'success',
              // })
              location.reload()
            }
            console.log(res, '注销')
          })
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="less">
@import url('../../assets/common.less');

.personal-center {
  max-width: 1190px;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 100px;
  display: flex;
  main {
    width: 110px;
    padding: 0 20px;
    flex: 1;
  }
}
.personal-center__menu {
  border: 1px solid #ccc;
  li {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    &:hover {
      color: @primary;
    }
    &.active {
      background-color: @primary;
      color: #fff;
      i {
        color: #fff;
      }
    }
    i {
      font-size: 22px;
      margin-right: 5px;
      color: @primary;
    }
  }
}
</style>