<template>
  <Wrapper>
    <div class="home">
      <!-- banner -->
      <HomeBanner></HomeBanner>

      <section class="middle">
        <ul class="w">
          <li @click="toService">
            <img src="@/assets/imgs/m1.png">
            <h2>招标投标</h2>
            <p>实现信息资源共享</p>
          </li>
          <li @click="toB2B">
            <img src="@/assets/imgs/m2.png">
            <h2>中药产业商城</h2>
            <p>专业的中药材交易平台</p>
          </li>
          <li @click="toB2C">
            <img src="@/assets/imgs/m3.png">
            <h2>亳采众长</h2>
            <p>零差价健康产业链</p>
          </li>
        </ul>
      </section>

      <!-- 集团介绍 -->
      <HomeIntro></HomeIntro>
      <!-- 公司业务 -->
      <HomeBusiness></HomeBusiness>

      <section class="develop">
        <div class="w">
          <div class="li">
            <div class="img">
              <img src="@/assets/imgs/dev1.png">
            </div>
            <div class="words">
              <h3>发展理念 七维</h3>
              <p>战略思维、资本思维、管理思维、中药材思维</p>
            </div>
          </div>
          <div class="li">
            <div class="img">
              <img src="@/assets/imgs/dev2.png">
            </div>
            <div class="words">
              <h3>发展理念 一心</h3>
              <p>产品思维、解决问题思维、底线思维、医者仁心</p>
            </div>
          </div>
        </div>
      </section>

      <section class="FazhanLicheng">
        <WaterTitle Chinese="发展历程" English="DEVELOPMENT HISTORY"></WaterTitle>
        <div class="w">
          <img :src="hisImg">
          <a class="look-more" @click="toHistory">查看详情&gt;&gt;</a>
        </div>
      </section>

      <!-- 服务介绍 -->
      <HomeService></HomeService>
      <!-- 合作单位 -->
      <HomeCooper></HomeCooper>
    </div>
  </Wrapper>

</template>

<script>
import HomeBanner from './sections/HomeBanner.vue'
import HomeBusiness from './sections/HomeBusiness.vue'
import HomeIntro from './sections/HomeIntro.vue'
import HomeService from './sections/HomeService.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import HomeCooper from './sections/HomeCooper.vue'
import Wrapper from '../components/Wrapper.vue'

export default {
  name: 'Home',
  components: {
    Wrapper,
    HomeBanner,
    HomeIntro,
    HomeBusiness,
    HomeService,
    WaterTitle,
    HomeCooper
  },
  data() {
    return {
      hisImg: ''
    }
  },
  created() {
    this.getHisory()
  },
  methods: {
    toHistory() {
      this.$router.push('/development')
    },
    toService() {
      let { pathname } = window.location
      let url = pathname + '#' + '/serviceTender'
      window.open(url, '_blank')
      // window.open(routeUrl.href, '_blank')
    },
    toB2B() {
      window.open('https://b2b.boyaocaigou.com/pc/', '_blank')
    },
    toB2C() {
      window.open('http://60.167.182.136:32028/index/', '_blank')
    },
    async getHisory() {
      const res = await this.$axios.getDevelopmentHistoryPage({
        offset: 1,
        limit: 1,
        statusCd: 0
      })
      this.hisImg = res.records[0].historyUrl
    }

  }
}
</script>

<style lang="scss" scoped>
section.middle {
  padding: 50px 0;
  text-align: center;
  .w {
    display: flex;
    justify-content: space-around;
  }
  li {
    cursor: pointer;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #515a6e;
  }
  p {
    color: #515a6e;
    font-size: 18px;
  }
}
section.develop {
  background-color: rgb(246, 250, 253);
  padding: 50px 0;
  .w {
    display: flex;
    justify-content: space-around;
  }
  .li {
    display: flex;
    align-items: center;
    .img {
      margin-right: 40px;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    p {
      width: 220px;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
section.FazhanLicheng {
  padding: 100px 0;
  text-align: center;
  .w {
    position: relative;
    margin-top: 50px;
    .look-more {
      color: #0e4db5;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
