export const B2B2C_URL = process.env.VUE_APP_B2B2C_URL;
export const B2B_URL = process.env.VUE_APP_B2B_URL;
export const INFO_URL = process.env.VUE_APP_INFO_URL_BASE + process.env.VUE_APP_INFO_URL;
export const QR_URL = process.env.VUE_APP_QR_URL;
export const COMPANY_ADDR='安徽省亳州市高新区古井大道与合欢路交叉口城建集团中药材产业园'
export const TEL_PHONE='0558-5588506'
export const CERTIFICATE='(皖)-经营性-2022-0020'
export const EMAIL='bzxh@bzcjhg.com '
export const BEIAN = '皖ICP备34160202000846号'

