<template>
  <div class="login-bg">
    <div class="login-outer">
      <div class="logo" @click="goIndex">
        <img src="@/assets/imgs/logo3.png" />
        <h1>安徽亳州中医药集团</h1>
      </div>
      <div class="login-box">
        <div class="login-inner">
          <h2>欢迎登录</h2>
          <el-form>
            <el-form-item label="">
              <el-input v-model="form.account" placeholder="手机号">
                <template slot="prepend"><i class="el-icon-user-solid icon-login"></i></template>
              </el-input>
            </el-form-item>
            <el-form-item label="" required v-if="isDuanxin">
              <div class="code-style">
                <el-input v-model="form.code" placeholder="请输入验证码" autocomplete="off" readOnly="true" style="width:" @click.native="inputControl">
                  <template slot="prepend"><i class="el-icon-mobile-phone icon-login"></i></template>
                </el-input>
                <span class="code-btn" @click="getSmsCode">{{
                isShowTime ? `重新获取(${time})s` : '获取验证码'
              }}</span>
              </div>
            </el-form-item>
            <el-form-item label="" v-else>
              <el-input type="password" v-model="form.password" placeholder="密码" auto-complete="new-password">
                <template slot="prepend"><i class="el-icon-lock icon-login"></i></template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn" type="primary" @click="loginBtn">登 录</el-button>
            </el-form-item>
          </el-form>
          <div class="agreement">
            <!-- <el-checkbox v-model="checked"><span class="hasRead">我已阅读</span><span class="lookAgree">《注册协议》</span></el-checkbox> -->
            <div class="dengxuan">
              <span class="dx-btn" @click="duanxinbtn"><img src="@/assets/imgs/dx-icon.png" alt="" />短信登录</span>
              <span class="mm-btn" @click="mimabtn"><img src="@/assets/imgs/mm-icon.png" alt="" />密码登录</span>
            </div>

          </div>
        </div>

        <svg class="login-nav-svg" @click="toRegister" width="70" height="70" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <linearGradient x1="17.621%" y1="50%" x2="100%" y2="50%" id="a">
              <stop stop-color="#06c" offset="0%" />
              <stop stop-color="#1ba1fb" offset="100%" />
            </linearGradient>
            <filter x="-10.7%" y="-10.7%" width="121.4%" height="121.4%" filterUnits="objectBoundingBox" id="c">
              <feGaussianBlur stdDeviation="6" in="SourceAlpha" result="shadowBlurInner1" />
              <feOffset dx="3" in="shadowBlurInner1" result="shadowOffsetInner1" />
              <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" in="shadowInnerInner1" />
            </filter>
            <path id="b" d="m1291 377 70 70v-70z" />
          </defs>
          <g transform="translate(-1291 -377)" fill="none" fill-rule="evenodd">
            <use fill="url(#a)" xlink:href="#b" />
            <use fill="#000" filter="url(#c)" xlink:href="#b" />
          </g>
        </svg>
        <div class="login-nav-text" @click="toRegister">注册</div>
      </div>
    </div>
    <div class="login-footer">
      <div class="copright">
        <span><i>地址：</i>{{ companyAddr }}</span>
        <span><i>电话：</i>{{ telPhone }}</span>
        <span><i>邮箱：</i>{{ email }}</span>
      </div>
      <div class="copright">
        <span class="cop2">互联网药品信息服务资格证书</span>
        <span class="cop2">{{ certificate }}</span>
        <img src="https://www.beian.gov.cn/img/new/gongan.png" alt=""
            style="vertical-align: middle; margin-left: 20px;margin-right: 2px" />
          <a class="beian"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34160202000846"
            target="_blank">皖公网安备：34160202000846</a>
          <i style="margin-left: 20px">备案号：</i>
          <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">{{
            beian
          }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { getSmsCode, smsLogin, loginPwd } from '@/api/modules'
import { checkPhone } from '@/utils/validator'
import Cookies from 'js-cookie'
import { COMPANY_ADDR, TEL_PHONE, CERTIFICATE, EMAIL, BEIAN } from '@/config/globalConfig'

export default {
  data() {
    return {
      isDuanxin: true,
      form: {
        account: '',
        code: '',
        password: ''
      },
      checked: false,
      isShowTime: false,
      time: 60,
      timer: null,
      companyAddr: COMPANY_ADDR,
      telPhone: TEL_PHONE,
      email: EMAIL,
      certificate: CERTIFICATE,
      beian: BEIAN
    }
  },
  methods: {
    inputControl(e) {
      e.target.readOnly = false
    },
    goIndex() {
      this.$router.push({
        path: '/'
      })
    },
    // 登录获取验证码
    getSmsCode() {
      if (this.isShowTime || !checkPhone(this.form.account)) {
        return
      }
      this.isShowTime = true
      this.time = 60
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time -= 1
        } else {
          this.isShowTime = false
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)

      getSmsCode({
        bizType: 'login',
        phone: this.form.account
      }).then((res) => {
        if (res.code == 200) {
          this.$message.warning('发送成功')
          console.log('发送成功')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    loginBtn() {
      if (this.isDuanxin) {
        console.log('短信登录')
        let that = this
        if (!that.form.code) {
          this.$message.warning('请输入验证码')
          return
        }
        if (this.form.account && this.form.code) {
          let smsdata = {
            phone: that.form.account,
            smsCode: that.form.code
          }
          smsLogin(smsdata)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success('登录成功')

                Cookies.set('token', res.data.accessToken)
                localStorage.setItem('token', res.data.accessToken)
                console.log("stringify:",JSON.stringify(res.data || {}));
                localStorage.setItem('userInfo', JSON.stringify(res.data || {}));
                this.$store.commit('userInfo', res.data || {})
                this.$router.replace({
                  name: 'Home'
                })
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((exp) => {
              console.log('异常信息' + exp.msg)
            })
        }
      } else {
        console.log('密码登录')
        let that = this
        if (!that.form.account) {
          this.$message.warning('请输入手机号码')
          return
        }
        if (!that.form.password) {
          this.$message.warning('请输入密码')
          return
        }
        if (this.form.account && this.form.password) {
          let requestData = {
            phone: that.form.account,
            password: that.form.password
          }
          loginPwd(requestData)
            .then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('登录成功')
                Cookies.set('token', res.data.accessToken)
                localStorage.setItem('token', res.data.accessToken)
                console.log("stringify:",JSON.stringify(res.data || {}));
                localStorage.setItem('userInfo', JSON.stringify(res.data || {}));
                this.$store.commit('userInfo', res.data || {})
                this.$router.push("/")
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((exp) => {
              console.log('异常信息' + exp.msg)
              // this.visibleDetail = false
            })
        } else {
          this.$message.warning('请输入对应信息')
        }
      }
    },
    duanxinbtn() {
      this.isDuanxin = true
    },
    mimabtn() {
      this.isDuanxin = false
    },
    toRegister() {
      this.$router.push('/register')
    }
  }
}
</script>

<style lang="less">
.login-bg {
  height: 100%;
  background-image: url('~@/assets/imgs/login-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    h1 {
      font-size: 26px;
      margin-left: 8px;
      color: #fff;
      font-weight: 700;
      line-height: 62px;
    }
    img {
      width: 62px;
      height: 62px;
    }
  }

  .login-footer {
    position: absolute;
    left: 0;
    bottom: 50px;
    right: 0;
    font-size: 12px;
    .copright {
      text-align: center;
      max-width: 1190px;
      margin: 10px auto 0;
      color: #fff;
      .beian {
        color: #fff;
      }
    }
  }

  .agreement {
    display: flex;
    align-items: center;
    .hasRead,
    .lookAgree {
      color: #fff;
    }
  }
  .dengxuan {
    font-size: 12px;
    width: 264px;
    margin-right: 10px;
    span {
      padding: 9px 13px;
      margin-right: 20px;
      font-size: 14px;
      background: #fff3e7;
      border-radius: 4px;
      color: #4a4a4a;
      line-height: 20px;
      cursor: pointer;

      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 4px;
        position: relative;
        top: -2px;
      }
    }

    .mm-btn {
      background: #e6f3ff;
    }
  }
  .code-style {
    display: flex;
    .el-input-group {
      width: 180px !important;
    }

    .code-btn {
      width: 88px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.login-box {
  width: 420px;
  height: 400px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-nav-svg {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.login-nav {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 0;
  height: 0;
  border-color: #1ba1fb transparent; /*上下颜色 左右颜色*/
  border-width: 100px 0 0 100px;
  border-style: solid;
}
.login-nav-text {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 3;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #fff;
  height: 240px;
}
.login-inner {
  h2 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 22px;
  }

  .el-form-item__content .el-input-group {
    border-radius: 50px;
    overflow: hidden;
    width: 264px;
  }
  .el-button--primary {
    border-radius: 50px;
    width: 264px;
  }
  .el-input-group__prepend {
    background-color: transparent;
    border: none;
    i {
      font-size: 24px;
      color: #fff;
    }
  }
  .el-input-group--prepend .el-input__inner {
    border: none;
    padding: 0;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
  }
}
</style>