<template>
  <section class="header" :class="isFixed?'fixed':''">
    <div class="w">
      <div class="logo">
        <img src="@/assets/imgs/logo.png" @click="navTo('/')" />
      </div>
      <ul>
        <li :class="activeTab===0?'active':''"><a @click="navTo('/')">首页</a></li>
        <li :class="activeTab===1?'active':''" @mouseenter="showSon(1)" @mouseleave="hideSon(1)">
          <a>集团介绍</a>
          <ol class="son-menu" v-show="showIntroSon">
            <li><a @click="navTo('/intro')">集团介绍</a></li>
            <li><a @click="navTo('/intro')">集团荣誉</a></li>
          </ol>
        </li>
        <li :class="activeTab===2?'active':''" @mouseenter="showSon(2)" @mouseleave="hideSon(2)">
          <a>公司业务</a>
          <ol class="son-menu" v-show="showBusSon">
            <li><a @click="navTo('/businessSector')">业务板块</a></li>
            <li><a @click="navTo('/productDisplay')">产品展示</a></li>
          </ol>
        </li>
        <li :class="activeTab===3?'active':''"><a @click="navTo('/development')">发展历程</a></li>
        <li :class="activeTab===4?'active':''" @mouseenter="showSon(4)" @mouseleave="hideSon(4)">
          <a>服务介绍</a>
          <ol class="son-menu" v-show="showSerSon">
            <li><a @click="navTo('/serviceTender')">招采投标</a></li>
            <li><a @click="navTo('/servicePrice')">价格指数</a></li>
            <li><a @click="navTo('/SupplyDemand')">供求信息</a></li>
            <li><a @click="navTo('/serviceHall')">服务大厅</a></li>
            <li><a @click="openNew('http://60.167.182.136:31818/login/')">安心购</a></li>
          </ol>
        </li>
        <li :class="activeTab===5?'active':''" @mouseenter="showSon(5)" @mouseleave="hideSon(5)">
          <a>商城</a>
          <ol class="son-menu" v-show="showMallSon">
            <li><a @click="openNew('https://b2b.boyaocaigou.com/pc/')">中药产业商城</a></li>
          </ol>
        </li>
        <li :class="activeTab===6?'active':''"><a @click="navTo('/cooper')">合作单位</a></li>
        
      </ul>
      <!-- <div class="tel">200-622-0000</div> -->
      <div class="user-btns" v-if="!userInfo.nickname">
        <div class="btn" @click="navTo('/login')">登录</div>
        <div class="btn active" @click="navTo('/register')">注册</div>
      </div>
      <div v-else>
        <span class="el-dropdown-link" @click="navTo('/personalCenter')">个人中心</span>
        <span style="margin-left: 20px;" v-if="userInfo.checkFlag!=1" class="el-dropdown-link" @click="navTo('/qualifications')">我要入驻</span>
      </div>
      <div >
        
      </div>
    </div>
  </section>
</template>

<script>
import store from '@/store'
import { getUserInfo } from '@/api/modules'
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      activeTab: null,
      showBusSon: false,
      showSerSon: false,
      showIntroSon: false,
      showMallSon: false,
      isFixed: false
    }
  },
  computed: {
    userInfo(params) {
      return this.$store.state.userInfo
    }
  },
  created() {
    this.initActiveTab()
  },
  mounted() {
    window.addEventListener('scroll', this.showFixed)
  },
  methods: {
    initActiveTab() {
      const path = this.$route.path
      if (path === '/index') {
        this.activeTab = 0
      }
      if (path === '/intro') {
        this.activeTab = 1
      }
      if (path === '/businessSector' || path === '/productDisplay') {
        this.activeTab = 2
      }
      if (path === '/development') {
        this.activeTab = 3
      }
      const serviceArr = [
        '/serviceTender',
        '/servicePrice',
        '/serviceArticle',
        '/marketDetail',
        '/supplyDemand',
        '/serviceHall'
      ]
      if (serviceArr.indexOf(path) >= 0) {
        this.activeTab = 4
      }
      if (path === '/cooper') {
        this.activeTab = 6
      }
    },
    navTo(path) {
      // 二级菜单需要新开页面
      const openUrls = [
        '/intro',
        '/businessSector',
        '/productDisplay',
        '/serviceTender',
        '/servicePrice',
        '/serviceArticle',
        '/serviceHall',
        '/marketDetail'
      ]
      if (openUrls.indexOf(path) >= 0) {
        const routeUrl = this.$router.resolve({
          path
        })
        let { host, pathname } = window.location
        let url = pathname + '#' + path
        window.open(url, '_blank')
      } else {
        this.$router.push(path)
      }
    },
    openNew(url) {
      window.open(url, '_blank')
    },
    showSon(index) {
      if (index === 1) {
        this.showIntroSon = true
      }
      if (index === 2) {
        this.showBusSon = true
      }
      if (index === 4) {
        this.showSerSon = true
      }
      if (index === 5) {
        this.showMallSon = true
      }
    },
    hideSon(index) {
      if (index === 1) {
        this.showIntroSon = false
      }
      if (index === 2) {
        this.showBusSon = false
      }
      if (index === 4) {
        this.showSerSon = false
      }
      if (index === 5) {
        this.showMallSon = false
      }
    },
    showFixed() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 350) {
        // 页面高度大于350执行操作
        this.isFixed = true
      } else {
        //  页面高度小于350执行操作
        this.isFixed = false
      }
    }
  }
}
</script>

<style lang='scss' scoped>
section.header {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 0;
  &.fixed {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 5px 10px #ccc;
  }
  .logo {
    cursor: pointer;
  }
  .w {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
  }

  ul {
    display: flex;

    li {
      position: relative;
      height: 50px;
      line-height: 50px;
      padding-right: 40px;
      cursor: pointer;
      a {
        color: #515a6e;
        font-size: 14px;
      }

      &.active {
        font-weight: 700;
        a {
          color: #181f2d;
        }
      }
      &.active::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 32px;
        height: 4px;
        background-color: #0054e4;
      }
    }
  }
  // 二级菜单
  .son-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background-color: rgba(255, 255, 255,0.5);;
    padding: 10px 0;
    width: 100px;
    box-shadow: 5px 5px 10px #ccc;
    li {
      padding-right: 0;
      height: 30px;
      line-height: 30px;
      a {
        display: block;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #666;
        &:hover {
          background-color: #0054e4;
          color: #fff;
        }
      }
    }
  }
  .tel {
    font-size: 18px;
    font-weight: 700;
    color: #5e6371;
  }
  .user-btns {
    display: flex;
    .btn {
      cursor: pointer;
      border: 1px solid #999;
      border-radius: 20px;
      padding: 8px 15px;
      color: #333;
      margin-left: 10px;
      letter-spacing: 2px;
      &.active {
        color: #0054e4;
        border-color: #0054e4;
      }
    }
  }
}
</style>
