<template>
  <section class="JituanJieshao">
    <div class="w">
      <div class="words col-6">
        <h2>集团介绍</h2>
        <div>
          <p>
            {{description}}
          </p>
        </div>
        <div class="look-more">
          <div class="water">INTRODUCE</div>
          <a @click="toIntro">查看详情&gt;&gt;</a>
        </div>
      </div>
      <div class="img col-6">
        <div class="block-blue"></div>
        <div class="block-green"></div>
        <img :src="introductionUrl" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      description: '',
      introductionUrl: ''
    }
  },
  created() {
    this.getMsg()
  },
  methods: {
    toIntro() {
      this.$router.push('/intro')
    },
    async getMsg() {
      const res = await this.$axios.getGroupIntroductionPage({
        offset: 1,
        limit: 1,
        statusCd: 0
      })
      this.description = res.records[0].description
      this.introductionUrl = res.records[0].introductionUrl
    }
  }
}
</script>

<style lang="scss" scoped>
section.JituanJieshao {
  background-color: #fafdff;
  padding: 50px 0;
  .words {
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: #333;
      line-height: 2;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 20px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 32px;
      height: 4px;
      background-color: #0054e4;
    }
  }
  .w {
    display: flex;
    .col-6 {
      width: 50%;
    }
  }
  .img {
    position: relative;
    .block-blue {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      width: 200px;
      height: 200px;
      background-color: #0e4db5;
    }
    .block-green {
      position: absolute;
      z-index: 1;
      left: -19px;
      bottom: 1px;
      width: 20px;
      height: 80px;
      background-color: rgb(122, 202, 91);
    }
    img {
      margin-top: 50px;
      margin-right: 50px;
      vertical-align: bottom;
      position: relative;
      z-index: 2;
    }
  }
  .look-more {
    position: relative;
    .water {
      font-size: 36px;
      font-weight: 700;
      color: #f3f5f8;
      text-shadow: #f3f5f8 1px 0 0, #f3f5f8 0 1px 0, #f3f5f8 -1px 0 0,
        #f3f5f8 0 -1px 0;
    }
    a {
      position: absolute;
      left: 0;
      bottom: 0;
      color: #0e4db6;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
