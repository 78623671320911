import fetch, {get, post } from '../../utils/fetch';


export function getCompanyByUser() {
    return get('/api/ysc/yscCompany/getCompanyByUser');
}
// 企业类型
export function getComTypes() {
    return fetch({
        url: '/api/indexconfig/getDictInfo?type=companytype',
        method: 'GET',
    });
}
export function editDesc(data) {
    return post('/api/ysc/yscCompany/updateDecriptions', data);
}
export function uploadOneImg() {
    return '/info-admin/upload/imageAndthumb'
}
export function uploadZiZhi() {
    return '/info-admin/upload/image'
}
export function uploadAvi() {
    return '/info-admin/api/mall/upload'
}
export function editCom(data) {
    return post('/api/ysc/yscCompany/save', data);
}
export function editProduct(data) {
    return post('/api/ysc/yscCompanyProductions/save', data);
}
export function delPro(data) {
    return post('/api/ysc/yscCompanyProductions/status/delete', data);
}
// 产品列表
export function getPros(params) {
    return get('/api/ysc/yscCompanyProductions/getAllList', params);
}
export function getProDetail(data) {
    return post('/api/ysc/yscCompanyProductions/getDetailById', data);
}