<template>
  <section class="HezuoDanwei">
    <div class="title-box">
      <WaterTitle Chinese="合作单位" English="COOPERATIVE UNIT"></WaterTitle>
    </div>
    <div class="cooper-swiper">
      <el-carousel trigger="click" indicator-position="outside" height="428px" arrow="never" :autoplay="false">
        <el-carousel-item v-for="(item, index) in links" :key="index">
          <div class="links">
            <div class="li" v-for="son in item" :key="son.id">
              <div class="before1"></div>
              <div class="before2"></div>
              <div class="before3"></div>
              <div class="before4"></div>
              <img :src="son.imgUrl" @click="openUrl(son.targetUrl)" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

    </div>
  </section>
</template>

<script>
import WaterTitle from '@/components/WaterTitle.vue'
export default {
  mixins: [],
  components: { WaterTitle },
  data() {
    return {
      links: []
    }
  },
  created() {
    this.getLinks()
  },
  methods: {
    async getLinks() {
      const res = await this.$axios.getCooperatorCompanyPage({
        offset: 1,
        limit: 100,
        statusCd: 0
      })
      let arr = res.records
      let links = []
      for (let i = 0, len = arr.length; i < len; i += 15) {
        links.push(arr.slice(i, i + 15))
      }
      this.links = links
    },
    openUrl(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang='scss' scoped>
.title-box {
  padding: 100px 0 50px;
}
.cooper-swiper {
  padding-bottom: 100px;
}
.links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 1240px;
  height: 428px;
  margin: 0 auto;
  .li {
    border-right: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    position: relative;
    z-index: 10;
    &:nth-child(5n) {
      border-right: 0;
      &::after {
        display: none;
      }
    }
    &:nth-last-of-type(-n+5) {
      // 最后5个
      border-bottom: 0;
      &::after {
        display: none;
      }
    }
    img {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    
    &::after {
      content: '';
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 4px;
      height: 4px;
      background-color: #E7E7E7;
      border-radius: 50%;
      z-index: 30;
    }
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .before1, .before2, .before3, .before4 {
      content: '';
      background-color: #fff;
      width: 30px;
      height: 30px;
      z-index: 20;
    }
    .before1 {
      position: absolute;
      left: -1px;
      top: -1px;
    }
    .before2 {
      position: absolute;
      right: -1px;
      bottom: -1px;
    }
    .before3 {
      position: absolute;
      right: -1px;
      top: -1px;
    }
    .before4 {
      position: absolute;
      left: -1px;
      bottom: -1px;
    }

  }
}
</style>

<style lang="scss">
.cooper-swiper {
  .el-carousel__indicator {
    .el-carousel__button {
      width: 6px;
      height: 6px;
      background-color: #e3e3e3;
      border-radius: 50%;
      opacity: 1;
    }
    &.is-active .el-carousel__button {
      border-radius: 3px;
      width: 12px;
      background-color: #0d53ff;
    }
  }
}
</style>