<template>
  <div class="bid-management">
    <div class="tabs">
      <el-tabs class="tabs-list" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane label="未缴纳" name="0"></el-tab-pane>
        <el-tab-pane label="审核中" name="1"></el-tab-pane>
        <el-tab-pane label="未通过" name="2"></el-tab-pane>
        <el-tab-pane label="已通过" name="3"></el-tab-pane>
        <el-tab-pane label="已退款" name="4"></el-tab-pane>
      </el-tabs>
    </div>
    <ul class="bid-list">
      <li
        class="card"
        v-for="(item, index) in dataList"
        :key="index"
        @click="detailBtn(item.payFlag,item.iid)"
      >
        <div class="card-title">
          <span class="l-style"></span><span>{{ item.zbtitle }}</span>
        </div>
        <ul class="card-con">
          <li class="card-con-item">
            <!-- <div class="item1">付款方式： {{ paymentWatch(item.payment) }}</div> -->
            <!-- <div class="item1">截止时间：{{ item.deadlinedate }}</div> -->
            <!-- <div class="item1">交货地：{{ item.plaadder }}</div> -->
          </li>
          <li class="card-con-item">
            <!-- <div class="item1">招标物料：{{ item.materialnamestr }}</div> -->
            <div class="item1">保证金金额：￥{{ item.depositNum }}</div>
            <div class="item1">保证金状态：{{ payFlagChange(item.payFlag) }}</div>
            <div class="item1">状态：{{ item.bidStatus }}</div>
            <div class="item1"></div>
          </li>
          <li class="card-con-item">
            <div class="card-con-imgs">
              <el-image
                class="images-style"
                v-for="url in item.pics"
                :key="url"
                :src="url"
                lazy
                :preview-src-list="item.pics"
              ></el-image>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <el-pagination
      class="pagination-style"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="pagination.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false">
      <el-dialog
      width="30%"
      title="上传凭证"
      :visible.sync="innerVisible"
      append-to-body>
      <el-upload
          class="upload-demo"
          ref="upload"
          action="#"
          :file-list="fileList"
          accept=".jpg , .png"
          :http-request="reportFileHttpRequest"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
          :auto-upload="true"
          list-type="picture-card"
          :limit=1
          :multiple="false"
          style="text-align: center">
        <i class="el-icon-upload"></i>
        <!-- <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> -->
        <div style="margin-bottom: 7px;" class="el-upload__tip" slot="tip">只能上传1张jpg/png文件</div>
      </el-upload>
      <div v-if="status == 4">
        <div style="margin-bottom: 10px;">保证金退回凭证</div>
        <div style="text-align: center;margin-bottom: 10px;">
          <img v-for="(item,index) in rtnPics" :key="index" style="height: 146px;max-width: 100%;" :src="item" alt="">
        </div>
      </div>
      <span v-if="status != 4 && status!=3" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUpload">提 交</el-button>
      </span>
    </el-dialog>
      <div class="bzjDt">
      <div class="unpay" v-if="status == 0">
        <div style="padding: 10px 20px 0 ;">
          <span style="font-size: 21px;font-weight: 580;">收款账户信息</span>
        </div>
        <el-divider></el-divider>
        <div class="list">
            <el-row>
              <el-col :span="5">
                <span>转账金额</span>
              </el-col>
              <el-col :span="18">
                <span>￥{{ depositNum }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <span>公司全称</span>
              </el-col>
              <el-col :span="18">
                <span>{{ companyName }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <span>开户银行</span>
              </el-col>
              <el-col :span="18">
                <span>{{ bank }}</span>
              </el-col>
            </el-row>
          <el-row>
            <el-col :span="5">
              <span>银行卡号</span>
            </el-col>
            <el-col :span="18">
              <span>{{ bankCode }}</span>
            </el-col>
          </el-row>

        </div>
        <div style="display: flex; width: 90%;margin: 30px auto 0;">
          <el-button @click="copyText(bank,bankCode)">复制信息</el-button>
          <el-button style="margin-left: 10px;color: #fff;background: linear-gradient(135deg, rgb(255 73 51), rgb(255 86 88));" color="" @click="goUpload" text="">已转账，提交付款凭证</el-button>
        </div>
      </div>
      <div class="examining" v-if="status == 1">
        <div style="height: 70px;font-size: 60px;color:#FF7F89;"><i class="el-icon-time"></i></div>
        <div>
          <span class="head" text="" size="40" bold>已提交付款凭证，审核中</span>
        </div>
        <div style="max-width: 330px;line-height: 21px;">将于1个工作日内核查，请留意当前页面变动如审核通过，将直接进入已支付状态</div>
        <div style="line-height: 50px;">未付款误操作? <span style="text-decoration: underline;cursor: pointer;" @click="status = 0">点我返回待支付状态>></span></div>
        <div style="width: 50%;">
          <el-button style="color: #fff;background:linear-gradient(to right, rgb(255, 73, 51), rgb(213, 51, 186))" shape="circle" @click="goUpload" text="">查看我的付款凭证</el-button>
        </div>
      </div>
      <div class="examining" v-if="status == 2">
        <div style="height: 70px;font-size: 60px;color:#FF7F89;"><i class="el-icon-circle-close"></i></div>
        <div>
          <span class="head" text="" size="40" bold>很抱歉,审核未通过</span>
        </div>
        <div>原因：{{ auditReason }}</div>
        <div style="width: 50%;">
          <el-button style="color: #fff;background:linear-gradient(to right, rgb(255, 73, 51), rgb(213, 51, 186))" shape="circle" @click="goUpload" text="">重新上传支付凭证</el-button>
        </div>
      </div>
      <div class="examining" v-if="status == 3">
        <div style="height: 70px;font-size: 60px;color:#FF7F89;"><i class="el-icon-circle-check"></i></div>
        <div>
          <span class="head" text="" size="40" bold>恭喜您,通过审核</span>
        </div>
        <div>保证金金额：{{ depositNum }}</div>
        <div style="display: flex; justify-content: center;width: 90%;margin: 0px auto 0;height: unset;">
          <el-button @click="goUpload" text="">查看支付凭证</el-button>
          <el-button style="margin-left: 10px;color: #fff;background:linear-gradient(to right, rgb(255, 73, 51), rgb(255, 86, 88))" color="" @click="goBj" text="">继续报价</el-button>
        </div>
      </div>
      <div class="examining" v-if="status == 4">
        <div style="height: 70px;font-size: 60px;color:#FF7F89;"><i class="el-icon-refresh"></i></div>
        <div style="line-height: 50px;">
          <span class="head" text="" size="40" bold>已退回保证金</span>
        </div>
        <div style="line-height: 50px;">保证金金额：{{ depositNum }}</div>
        <div style="line-height: 30px;">咨询电话：13332054654</div>
        <div style="width: 50%;">
          <el-button style="color: #fff;background:linear-gradient(to right, rgb(255, 73, 51), rgb(213, 51, 186))" shape="circle" @click="goUpload" text="">查看支付凭证</el-button>
        </div>
      </div>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { INFO_URL } from '@/config/globalConfig'
import { uploadFile } from '@/api/modules/upload'
import { getMyInquery,getUserPayPageList,doadd,getAccountInfo } from '@/api/modules/bid'
import { getDictInfo } from '@/api/modules'
export default {
  created() {
    //this.getMyInqueryMaterials()
    this.getMyInquery()
    getAccountInfo().then(res=>{
      this.companyName = res.data.companyName
      this.bankCode = res.data.bankCode
      this.bank = res.data.bank
    })
  },
  data() {
    return {
      status:'',
      iid:null,
      dialogVisible: false,
      innerVisible: false,
      activeName: '-1',
      dataList: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
      urls: [],
      form: {
        name: '',
        qstatus: '',
        payment: 2,
      },
      payment: [],
      dataList1: [],
      fileList:[],
      infoUrl: INFO_URL,
      payPic:'',
      rtnPics:'',
      depositNum:0,
      auditReason:'',
      companyName: "毫州毫药某某有限公司",
      bank: "中国农业银行股份有限公司毫州支行",
      bankCode: "2280 1501 0400 07583",
      // spid:null
    }
  },

  computed: {
    paymentWatch() {
      return function (bidStatus) {
        if (bidStatus == null) {
          return ''
        }

        let paymentFind = this.payment.find((item) => item.value == bidStatus)
        return paymentFind ? paymentFind.label : ''
      }
    },
  },
  mounted() {
    this.getPayment()
  },
  // created(){
  //   // this.spid = this.$route.query.spid
  // },
  methods: {
    copyText(bank,bankCode){
      let value = bank+bankCode
      navigator.clipboard.writeText(value).then(() => {
      this.$message.success('复制成功');
    });
      // const input = document.createElement('input');
      // input.value = bank+ ' ' +bankCode;
      // document.body.appendChild(input);
      // input.select()
      // document.execCommand('Copy');
      // input.remove();
    },
    payFlagChange(val){
      let con = ''
      switch (val) {
        case 0:
          con = '待缴纳'
          break;
        case 1:
          con = '审核中'
          break;
        case 2:
          con = '未通过'
          break;
        case 3:
          con = '已通过'
          break;
        case 4:
          con = '已退回'
          break;
        default:
          break;
      }
      return con
    },
    // 上传文件 此时会触发组件的http-request
submitUpload() {
  doadd({
    iid:this.iid,
    payPics:this.payPic
  }).then(res=>{
    if(res.code==200){
      this.$message.success(res.msg)
      this.innerVisible = false
      this.dialogVisible = false
      this.getMyInquery()
    }else{
      this.$message.error(res.msg)
    }
  })
    // this.$refs.upload.submit();
},
// 文件列表移除文件时的钩子
handleRemove(file, fileList) {
    console.log(file, fileList);
},
// 点击文件列表中已上传的文件时的钩子
handlePreview(file) {
    console.log(file);
},
// 文件上传之前的钩子
beforeAvatarUpload(file) {
  const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg'
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!')
      }
    if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
    }
    return isLt2M&&isJPG;
},
// 自定义上传
reportFileHttpRequest(fileObject) {
      console.log(fileObject, 'fileObject')
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          let pic = this.infoUrl + res.path
          this.payPic = res.path
          console.log(this.payPic, 'payPic')
          // this.fileList = pic.split(',')
          // this.fileList = pic.split(',').map(item=>{return {url: item}})
        })
        .catch((exp) => {
          console.log(exp)
        })
    // if (this.$refs.upload) {
    //     this.$refs.upload.clearFiles() // 清除上传的文件
    // }
    // const _this = this
    // // 开始上传文件 新建一个formData
    // const formData = new FormData();//FormData对象，添加参数只能通过append('key', value)的形式添加
    // formData.append("file", param.file);//添加文件对象
    //     doadd({
    //       iid:this.iid,
    //       payPics:param.file
    //     })
    //         .then(function (resp) {
    //         if (resp.code == 200) {
    //             _this.$message({
    //                 message: '上传成功',
    //                 type: 'success'
    //             });
    //         } else {
    //             _this.$message(resp.msg)
    //         }
    //     })
},
    goUpload(){
      this.innerVisible = true

    },
    goBj(){
      let url = this.$router.resolve('/serviceTender')
      window.open(url.href,'_blank')
    },
    getPayment() {
      getDictInfo('BID_PAY_TYPE').then((res) => {
        console.log(res, '2121212121')
        if (res.code == 200) {
          const map = new Map(Object.entries(res.data))
          this.payment = this.mapToObj(map)
        }
      })
    },

    mapToObj(strMap) {
      let arr = new Array()
      for (let [k, v] of strMap) {
        let obj = Object.create(null)
        obj.value = parseInt(k)
        obj.label = v
        arr.push(obj)
      }
      return arr
    },
    handleClose(done) {
        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     done();
        //   })
        //   .catch(_ => {});
      },
    detailBtn(status,iid) {
      this.status = status
      this.iid = iid
      let obj = {}
      let pic = []
      obj = this.dataList.find(item=>item.iid == iid)
      if(obj.payPics){
        pic = [this.infoUrl + obj.payPics].map(item2=>{return {url: item2}})
      }else{
        pic = []
      }
      if(obj.rtnPics){
        this.rtnPics = obj.rtnPics.split(',').map(item2=>{return this.infoUrl+item2})
      }else{
        this.rtnPics = []
      }
      console.log(obj.rtnPics)
      this.fileList = pic
      this.depositNum = obj.depositNum
      this.auditReason = obj.auditReason
      this.dialogVisible = true
      // this.$router.push({
      //   name: 'BidDetail',
      //   query: {
      //     id: inquiryId,
      //   },
      // })
    },
    getMyInqueryMaterials() {
      let that = this
      getMyInqueryMaterials({
        name: that.form.name,
        qstatus: that.form.qstatus,
        payment: that.form.payment,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size,
      }).then((res) => {
        //console.log(res)
        let datas = res.data.content
        //let vals = [];
        for (var i = 0; i < datas.length; i++) {
          // console.log(datas[i]);
          if (datas[i].pics != null) {
            datas[i].pics = datas[i].pics.split(',')
          } else {
            datas[i].pics = []
          }
          //vals.push(datas[i].deadline)
        }
        //console.log(vals)
        // that.countdown(vals);
        console.log('datas')
        console.log(datas)
        that.dataList = datas
        that.pagination.total = res.data.totalElements
        that.pagination.size = res.data.size
        that.pagination.page = res.data.number + 1
      })
    },

    getMyInquery() {
      let that = this
      getUserPayPageList({
        payFlag: that.status,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size,
      }).then((res) => {
        let datas = res.data.content
        console.log('datas')
        console.log(datas)
        // for (let index = 0; index < datas.length; index++) {
          
          
        // }
        for (var i = 0; i < datas.length; i++) {
          switch (
            datas[i].bidStatus //0:投标，1:已经开标 2:议价中 3:议价结束 4:已中标
          ) {
            case 0:
              datas[i].bidStatus = '已发起'
              break
            case 1:
              datas[i].bidStatus = '开标中'
              break
            case 2:
              datas[i].bidStatus = '二次报价'
              break
            case 3:
              datas[i].bidStatus = '评审中'
              break
            case 4:
              datas[i].bidStatus = '已中标'
              break
            case 5:
              datas[i].bidStatus = '招标审核中'
              break
            case 6:
              datas[i].bidStatus = '招标审核不通过'
              break
            case 7:
              datas[i].bidStatus = '投标中'
              break
            case 8:
              datas[i].bidStatus = '已结束'
              break
            case -1:
              datas[i].bidStatus = '审核不通过'
              break
            case -2:
              datas[i].bidStatus = '流标'
              break
            case -3:
              datas[i].bidStatus = '废标'
              break
          }
          datas[i].deadlinedate = datas[i].updateDate.split('T')[0]
        }
        that.dataList = datas
        that.pagination.total = res.data.totalElements
        that.pagination.size = res.data.size
        that.pagination.page = res.data.number + 1
      })
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.getMyInquery()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getMyInquery()
    },
    handleClick(tab, event) {
      console.log(tab, event, '0.0.0.0')
      this.status = tab.name=='-1'?'':tab.name
      this.getMyInquery()

      //console.log(tab.name)
    },
    formatDate(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          )
        }
      }
      return fmt
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body{
  padding: 10px 20px;
}
/deep/ .el-col-18{
font-weight: bold;
}
/deep/ .el-divider--horizontal{
  margin: 12px 0;
}
 .bzjDt{
  //  background: linear-gradient(180deg, rgb(255, 73, 51), #FF6C96,#f8f8f8);
   background-image: linear-gradient(to bottom, rgb(255, 73, 51,1), #f8f8f8);
    // background: #f1f1f1;
        // min-height: calc(100vh - 20px);
        padding: 10px;
        // min-height: 300px;
      }
  .unpay{
    border-radius: 6px;
    padding-bottom: 30px;
    background-color: #FFFDF2;
    .list{
      font-size: 15px;
      line-height: 27px;
      padding: 0 20px;
      el-row{
        &>*:not(:first-child){
          // span{
            font-weight: bold;
          // }
        }
        // & > span:nth-child(2){
          // font-weight: bold;
        // }
      }
      span{
        display: block;
      }
    }
  }
  .examining{
    background-color: #FFFDF2;
    color: rgb(126, 126, 126);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // background-color: #fff;
    border-radius: 6px;
    padding-bottom: 10px;
    padding-top: 10px;
    .head{
      font-size: 21px;
      font-weight: bold;
    }
    div{
      height: 50px;
    }
  }
.bid-management {
  max-width: 1100px;
  margin: auto;
  .tabs {
    background-color: #fff;
    padding: 20px 0;
    .tabs-list {
      max-width: 1000px;
      margin: auto;
      padding: 0 20px;
    }
    /deep/.el-tabs__header {
      margin-bottom: 0;
    }
    /deep/.el-tabs__item.is-active {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__item:hover {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(20, 99, 158, 1);
    }
  }
  .bid-list {
    .card:hover {
      box-shadow: 0 0 10px rgba(20, 99, 158, 0.3);
    }
    .card {
      cursor: pointer;
      box-shadow: 0 0 10px #ddd;
      border-radius: 8px;
      padding: 20px 20px 10px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      &-title {
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .l-style {
          display: inline-block;
          width: 10px;
          height: 18px;
          background: rgb(20, 99, 158);
          margin-right: 6px;
          border-radius: 4px;
        }
        .count-down {
          width: 26%;
          margin-left: auto;
          text-align: center;
          span {
            color: red;
          }
        }
      }
      &-con {
        font-size: 14px;
        width: 100%;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
          .item1 {
            width: calc((100% - 100px) / 2);
          }
          // .item1:last-child{
          //     width: 200px;
          // }
          .item2 {
            position: relative;
            .btns {
              position: absolute;
              font-size: 16px;
              padding: 4px 16px;
              border: 1px solid rgb(20, 99, 158);
              border-radius: 4px;
              top: -10px;
              right: 0;
              cursor: pointer;
              color: rgb(20, 99, 158);
            }
            .btns:hover {
              background-color: rgba(20, 99, 158, 0.1);
            }
          }
          .item3 {
            color: #999;
            cursor: pointer;
          }
        }
      }
      &-con-right {
        width: 20%;
        text-align: center;
        &-img {
          width: 100px;
          height: 100px;
          margin: 10px auto 0;
          display: block;
        }
        &-value {
          font-size: 12px;
        }
      }
      &-con-imgs {
        width: 100%;
        .con-imgs-item {
          width: 50px;
          height: 50px;
          margin: 0 4px;
        }
      }
      .images-style {
        width: 10%;
        padding: 4px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }
  }
  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
